import React, {Dispatch, useContext, useEffect} from "react";
import * as flow from './Flow/PreviewTools/index'
import DynamicObject from "../models/dynamic-object";
import {PreviewStep as PreviewStepModel} from "../models";
import PostContext from "../storage/PostContext";

interface Props {
    config: DynamicObject,
    setConfigData: React.Dispatch<React.SetStateAction<any>>;
    selectFieldConfig: DynamicObject,
    tempFormData:DynamicObject
}

interface RefType {
    getState: () => DynamicObject
}

const MapperSelectField = (props: Props): JSX.Element => {
    const {config} = props
    const flowRefs: DynamicObject = {}
    const postCtx = useContext(PostContext)


    {/*RECURSION FUNCTION TO GET CHILD'S AND SUB COMPONENT INSIDE PREVIEW STEP */
    }
    const Mapper = (config: DynamicObject) => {
        const typeName = config.type.capitalize();
        const identifier = config.identifier
        const attributes: DynamicObject = {}
        const Component = flow[typeName as keyof typeof flow]

        {/*PREPARE REF*/
        }
        flowRefs[identifier] = React.createRef<RefType>()

        {/*CHECK IF THE COMPONENT NOT EXIST*/
        }
        if (!Component) {
            throw new Error(`Component ${typeName} doesn't Exists`)
            return <></>
        }

        const oldValue = postCtx.data.form[identifier]

        useEffect(function () {

            return () => {
                if (oldValue !== postCtx.data.form[identifier] && props.selectFieldConfig.linked?.length) {
                    const form = {
                        ...postCtx.data.form,
                        ...props.tempFormData,
                        [`${identifier}`]: postCtx.data.form[identifier]
                    }

                    for (const field of props.selectFieldConfig.linked)
                        form[field] = ''

                    const previewConfig: DynamicObject = postCtx.previewStep
                    const lastPreview = previewConfig.childs.filter((child: any) => {
                        if (props.selectFieldConfig.linked?.includes(child.identifier)) {
                            delete child.values
                            child.values = []
                            return {
                                ...child
                            }
                        } else {
                            return child
                        }
                    })

                    const finalConfig = {
                        ...previewConfig,
                        childs: lastPreview
                    }

                    postCtx.updateData({
                        form: {...form},
                        previewStep: finalConfig
                    })
                }
            }
        }, [])


        attributes.tempFormData = props.tempFormData
        return (
            <Component setConfigData={props.setConfigData} config={config as any} selectFieldConfig={props.selectFieldConfig} {...attributes} >
                {config.childs && config.childs.map((child: DynamicObject) => {
                    return Mapper(child)
                })}
            </Component>
        )
    }


    {/*
    - THIS IS CHILD'S FOR PREVIEW STEP
    - MAPPING FROM FOLDER PREVIEW TOOLS
    */
    }
    return Mapper(config)
}

export default MapperSelectField