import React, {useContext, useEffect, useState} from "react";
import {AxiosError, AxiosResponse} from "axios";
import * as flow from './Flow/index'
import DynamicObject from "../models/dynamic-object";
import {Args, UseHttpResponse} from "../hooks/use-http/interfaces";
import ComponentsRouting from '../config/components-routing.config'
import PostContext from "../storage/PostContext";
import {MediaMapper} from "../helpers/media.functions";
import {getFinalPayload, parentCallBack, removeMatchingKeys} from "../helpers/functions";
import UIContext from "../storage/UIContext";
import {jsx} from "@emotion/react";
import JSX = jsx.JSX;
import MediasCacher from "../models/medias-cacher";
import LanguageContext from "../storage/LanguageContext";
import {generateToken} from "../helpers/auth.helper";
import 'opensooq-services/dist/esm/css/Main.import.css'

import {PaymentWidget} from "opensooq-services";

interface Config {
    [key: string]: any
}

interface Props {
    config: Config,
    nextApi: UseHttpResponse<DynamicObject>,
    Previous?: React.FunctionComponent
}

interface RefType {
    getState: () => DynamicObject,
}


const flowRefs: DynamicObject = {}

const MapperFlow = (props: Props): JSX.Element => {
    const {config, nextApi, Previous} = props
    const postCtx = useContext(PostContext)
    const langCtx = useContext(LanguageContext)
    const uiCtx = useContext(UIContext)
    const [token, setToken] = useState<string>('')

    useEffect(() => {
        generateToken().then((token) => {
            setToken(token ?? '');
        })
    }, [])

    const Mapper = (config: Config) => {
        const typeName = config.type.capitalize();
        const identifier = config.identifier
        const attributes: DynamicObject = {}
        const Component = flow[typeName as keyof typeof flow]

        flowRefs[identifier] = React.createRef<RefType>()

        if (!Component) {
            throw new Error(`Component ${typeName} doesn't Exists`)
            return <></>
        }
        attributes.ref = flowRefs[identifier]


        {/*
        - CHECK IF THE COMPONENT WILL REDIRECT TO ANOTHER COMPONENT OR NOT ( FROM ROUTING CONFIG )
        - COMPONENT ROUTING DOESN'T HAS NEXT BUTTON
        - COMPONENT ROUTING WHEN ( ON CLICK ON COMPONENT , OR SELECT ELEMENT FROM MENU , ... )
        */
        }

        function loadData() {
            const data: DynamicObject = {}
            for (const key in flowRefs) {
                const ref = flowRefs[key]
                if (!ref?.current?.state?.get)
                    continue

                const state = ref.current.state.get()
                const keys = key.match(/\b(\w+)\b/g) || []

                if (keys.length === 2) {

                    let mainKey = keys[0] || ''
                    if (!data[mainKey])
                        data[mainKey] = {}

                    for (let i = 1; i < keys.length; i++) {
                        let name = keys[i]
                        data[mainKey][name] = state
                    }
                } else {
                    if (typeof state === "object") {
                        if (!data[key])
                            data[key] = {}

                        removeMatchingKeys(Object.keys(data[key]), state)
                        data[key] = {...data[key], ...state}
                    } else {
                        data[key] = state

                    }
                }
            }

            return data
        }

        if (ComponentsRouting[typeName as keyof typeof ComponentsRouting]) {
            {/*PREPARE NEXT API OR ( NEXT STEP )*/
            }
            attributes.nextApi = nextApi
            const request = nextApi.request
            nextApi.request = (args?: Args) => {
                const data: DynamicObject = loadData();
                // const data = loadData()
                postCtx.updateData({
                    form: {...data}
                })

                const onError = (error: AxiosError) => {

                    if (error.response?.status === 422) {
                        const data = error.response?.data as DynamicObject


                        uiCtx.setErrors(data?.result?.errors as [])
                    }
                    uiCtx.setLoading(false)


                }


                const onSuccessNextApi = (response: AxiosResponse) => {

                    if (postCtx.data.step.identifier.includes('CV') || postCtx.data.step.identifier.includes('init_SubCategoryStepDraft')) {
                        parentCallBack('without_confirm_dialog')
                    } else {
                        parentCallBack('with_confirm_dialog')
                    }
                    const stepData = {
                        workflow_id: response.data.workflow_id,
                        draft_id: response.data.draft.id,
                        step: response.data.step,
                        start_time: Date.now(),
                        previous: response.data.previous,
                    }


                    const mediaResult = MediaMapper(response.data.media)

                    MediasCacher.getInstance().concat(mediaResult)
                    console.log('load')

                    const isCV = response.data.step?.identifier.includes('CV')

                    const payload = isCV ? response.data.draft.payload : getFinalPayload(response.data.draft.payload)

                    console.log(stepData)

                    postCtx.updateData({
                        ...stepData,
                        form: {
                            ...data,
                            ...payload
                        },
                        media: mediaResult
                    })


                    uiCtx.setLoading(false)
                    uiCtx.setErrors([])
                }


                request({
                    ...args,
                    config: {
                        ...args?.config,
                        data: {
                            ...data,
                            ...args?.config?.data,
                        }
                    },

                    callbacks: {
                        ...args?.config?.callbacks,
                        error: onError,
                        success: onSuccessNextApi
                    }
                })
            }
        }

        attributes.config = config
        attributes.ref = flowRefs[identifier]


        useEffect(() => {
            for (const key in uiCtx.errors) {
                const errorObject = uiCtx.errors[key] as DynamicObject
                const ref = flowRefs[errorObject.field]

                if (!ref?.current?.validation?.set)
                    continue

                if (key == "0") {
                    let element = document.getElementById(errorObject.field)
                    element?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
                }

                ref.current.validation.set(errorObject?.message)

            }

            // if (Object.values(uiCtx.errors)) {
            //     // const data = loadData()
            //     // postCtx.updateData({
            //     //     form: {...data}
            //     // })
            // }


        }, [uiCtx.errors])

        return <>
            {
                React.createElement(Component as React.FunctionComponent, {
                    ...attributes,
                }, config.childs && config.childs.map((child: Config) => {
                    return Mapper(child)
                }))
            }</>
    }

    return <>{Mapper(config)}</>

}

export default MapperFlow
