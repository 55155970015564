import React, {useContext, useEffect, useState} from "react";
import {Props} from "./interface";
import styles from './Section.module.css'
import {getSizes} from "../../../../helpers/functions";
import UIContext from "../../../../storage/UIContext";
import PostContext from "../../../../storage/PostContext";
import {FormControlLabel, Radio} from "@mui/material";
import DynamicObject from "../../../../models/dynamic-object";
import SectionCacher from "../../../../models/section-cacher";

const Section = (props: Props): JSX.Element => {

    const {config} = props

    const [childAction, setChildAction] = useState('')
    /************************************
     *           CSS STYLE
     * *********************************/
    const containerStyle = {
        "--background-color": props.config?.background?.color,
        "--border-color": props.config?.border?.color
    } as React.CSSProperties

    /************************************
     *      INLINE TITLE STYLE
     * *********************************/
    const titleStyle = {
        fontWeight: config.title?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.title?.style?.size),
        color: config.title?.color,
    } as React.CSSProperties

    /************************************
     *     INLINE SUBTITLE STYLE
     * *********************************/
    const subTitleStyle = {
        fontWeight: config.subTitle?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.subTitle?.style?.size),
        color: config.subTitle?.color,
    } as React.CSSProperties

    const checkTypeOfSectionChild = (type: string) => {
        const targetChild = props.config.childs.filter(child => child.type.toLowerCase() == type.toLowerCase())
        if (targetChild?.length == 1) {
            return targetChild[0].identifier
        } else {
            return false
        }
    }

    useEffect(() => {
        const result = checkTypeOfSectionChild("textLabelWithAction")
        if (result)
            setChildAction(result)
    }, [])

    const attributes: DynamicObject = {
        id: props.config.identifier,
        className: styles.container,
        style: containerStyle
    }
    if (childAction) {
        const callback = SectionCacher.getInstance().get(childAction)
        if (callback) {
            attributes.className=`${attributes.className} cursor__pointer`
            attributes.onClick = () => {
                callback()
            }
        }
    }


    return (
        <div {...attributes}>
            {config?.title && <h1 style={titleStyle}>{config.title?.locale}</h1>}
            {config?.subTitle && <p style={subTitleStyle}>{config?.subTitle?.locale}</p>}
            {props.children}
        </div>
    )
}

export default Section