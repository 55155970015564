import RefType from "../../models/ref-type";
import DynamicObject from "../../models/dynamic-object";
import {ForwardedRef, useImperativeHandle, useState} from "react";


interface RefDataType extends RefType<DynamicObject> {
}

const useValidation = (ref: ForwardedRef<RefDataType>) => {
    /* ERRORS STATE*/
    const [error, setError] = useState<string>('')
    useImperativeHandle<RefDataType, RefDataType>(ref, () => {
        return {
            setValidationError: (error: string) => {
                setError(error)
            },
        }
    })


    return {
        error,
        ref
    }

}
export default useValidation;