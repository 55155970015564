import React, {useContext, useRef, useState} from "react";
import {Props} from "./interface";
import {MdEdit} from "react-icons/md";
import {IoIosAttach} from "react-icons/io";
import styles from './UploadImage.module.css'
import {useTranslation} from "react-i18next";
import {LinearProgressLabel} from "../../../UI";
import {Camera} from "../../../../assets/images";
import uploadCv from "../../../../api/upload-cv.api";
import {getSizes} from "../../../../helpers/functions";
import PostContext from "../../../../storage/PostContext";
import uploadMediaApi from "../../../../api/upload-media.api";
import DynamicObject from "../../../../models/dynamic-object";
import languageContext from "../../../../storage/LanguageContext";
import {generateFileHash, getFullPathMedia} from "../../../../helpers/media.functions";

const UploadImage = (props: Props) => {

    /************************************
     *      INLINE TEXT STYLE
     * *********************************/
    const textStyle = {
        fontWeight: props.config.text?.style?.bold ? "bold" : "normal",
        fontsize: getSizes(props.config.text?.style?.size) + "px",
        fontColor: props.config.text.color,
        textAlign: props.config.text.align
    } as React.CSSProperties


    /************************************
     *      INLINE BORDER STYLE
     * *********************************/
    const backgroundBorderStyle = {
        "--backgroundColor": props.config?.background?.color,
        "--border-color": props.config?.border?.color,
    } as React.CSSProperties

    /************************************
     *           CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)
    const langCtx = useContext(languageContext)


    /************************************
     *          TRANSLATION
     * *********************************/
    const {t} = useTranslation()


    /************************************
     *           CONSTANTS
     * *********************************/
    const defaultImage = postCtx.data.media[props.config.identifier]?.medias ? Object.values(postCtx.data.media[props.config.identifier].medias)[0].url : ''
    const defaultResumeUrl = postCtx.data.media[props.config.identifier]?.medias ? Object.values(postCtx.data.media[props.config.identifier].medias)[0].url : ''

    const fileInput = useRef<HTMLInputElement>(null);
    const imgInput = useRef<HTMLInputElement>(null);

    /************************************
     *             STATES
     * *********************************/
    const [imgProgress, setImgProgress] = useState<DynamicObject>({progress: 0, isUploaded: true})
    const [docProgress, setDocProgress] = useState<DynamicObject>({progress: 0, isUploaded: true})
    const [isValidImage, setValidImage] = useState(true)
    const [isValidFile, setValidFile] = useState(true)
    const [imageData, setImageData] = useState<string | undefined>(defaultImage);
    const [resumeUrl, setResumeUrl] = useState<string | undefined>(defaultResumeUrl);


    /*---------------------------------------------------------------
   *                            FUNCTIONS
   * --------------------------------------------------------------*/

    const handleFileClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        fileInput.current!.click();
    };

    const handleImgClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        imgInput.current!.click();
    };

    const uploadMedia = (file: File) => {

        const reader = new FileReader();
        reader.readAsArrayBuffer(file)
        reader.onload = (e) => {
            uploadMediaApi({
                onUploadProgress: (progress: number) => {

                },
                onDownloadProgress: (progress: number) => {

                },
                data: e?.target?.result,
                headers: {
                    "Content-Type": "multipart/form-data",
                }

            }).then(response => {


                saveToDraft(response.data.uri)
                    .then(responseDraft => {
                        const uri = Object.values(responseDraft.data.result.data[props.config.identifier])[0]['uri']
                        const url = getFullPathMedia(uri)
                        const hash = generateFileHash(file)
                        if (!props.config.document)
                        {
                            postCtx.updateData({
                                ...postCtx.data,
                                media: {
                                    ...postCtx.data.media,
                                    [`${props.config.identifier}`]: {
                                        medias: {
                                            [`${hash}`]: {
                                                uri,
                                                url,
                                                progress: 100,
                                                isMain: false
                                            }
                                        }
                                    }
                                }

                            })
                        }
                    });
            })
        }
    }

    const saveToDraft = (uri: string) => {
        const data: DynamicObject = {
            [`${props.config.identifier}`]: {
                uri: [
                    uri
                ],
            },
            draftId: postCtx.data.draft_id,
            workflowId: postCtx.data.workflow_id,
        }


        return uploadCv({
            draftId: postCtx.data.draft_id,
            data,
            bucket: 8
        })
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files![0];
        if (!file.name.match(/(\.doc|\.docx|\.odt|\.pdf|\.jpg|\.jpeg|\.png|\.gif)$/i)) {
            setValidFile(false);
            setResumeUrl('')
        } else {
            setValidFile(true);
            uploadMedia(file);
            setResumeUrl(URL.createObjectURL(file))
        }

    };

    const handleViewResumeClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (resumeUrl) {
            window.open(resumeUrl);
        }
    };

    const handleImgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files![0];
        if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
            setValidImage(false);
            setImageData('')
        } else {
            const fileUrl = URL.createObjectURL(file)
            setValidImage(true)
            uploadMedia(file);
            setImageData(fileUrl)
        }

    };

    /*---------------------------------------------------------------*/


    return (

        <>
            {props.config.uIType == "rectangle" &&
                <div onClick={handleFileClick} className={`${styles.container}`} style={backgroundBorderStyle}>
                    <input type="file" style={{display: "none"}} onChange={handleFileChange} ref={fileInput}/>
                    {/*{imageData && <img width="100px" height="100px" src={imageData} alt="Selected image" />}*/}
                    <div className={`row ${styles.row}`}>
                        <div className={`col-lg-6 col-md-6 col-6`}>
                            <h2 className={`${styles.text}`} style={textStyle}>{props.config.text.locale}</h2>
                            {resumeUrl && <div style={{color: "#0179ff", cursor: "pointer"}}
                                               onClick={handleViewResumeClick}>{t('viewCV')} </div>}
                        </div>
                        <div className={`col-lg-6 col-md-6 col-6 ${styles.icon_col}`}>
                            <div className={`${styles.icon_container} cursor__pointer`}>
                                <IoIosAttach style={{color: "white", height: "50px", width: "50px"}}></IoIosAttach>
                            </div>

                        </div>
                        {docProgress.isUploaded != true && <LinearProgressLabel variant="buffer" value={40}
                                                                                container={{className: styles.progress}}/>}

                    </div>
                </div>
            }

            {props.config.uIType == "square" &&
                <div onClick={handleImgClick} className={`${styles.container}`} style={backgroundBorderStyle}>
                    <input type="file" style={{display: "none"}} onChange={handleImgChange} ref={imgInput}/>

                    <div className={`row ${styles.row}`}>
                        <div className={`${styles.square_type}`}>
                            {imageData ? <img
                                src={imageData}
                                className={styles.square_image}
                            ></img> : <div className={styles.camera_icon}>
                                <img src={Camera}/>
                            </div>}

                            <div className={`${styles.edit_icon} cursor__pointer`}>
                                <div className={styles.align_items}>
                                    <MdEdit className={styles.align_icon}></MdEdit>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!isValidImage &&
                        <div className={"d-flex justify-content-end"}><p style={{color: "red"}}>* Invalid uploaded
                            image! </p></div>}
                </div>
            }
            {!isValidFile && props.config.uIType == "rectangle" &&
                <div><p style={{color: "red", marginTop: "-10px"}}>* Invalid uploaded
                    file! </p></div>}
        </>
    )
}

export default UploadImage