import React, {useState} from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import {MediaAction, MediaActionKind, StateDataType} from "./MediaUpload.interfaces";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styles from './EditorMenu.module.css'
import {useTranslation} from "react-i18next";

interface EditorMenuProps {
    mediaKey: string,
    isMain: boolean,
    onClickMenuItemHandler: (e: React.MouseEvent, handler: () => any) => void,
    deleteImageHandler: (e: React.MouseEvent, mediaId: string) => void,
    onClickChangeMediaHandler: (hash: string, isMainImage: boolean) => void,
    changeMainImage: (hash: string) => void,
    state: StateDataType
}

interface AnchorState {
    [key: string]: HTMLElement
}


const EditorMenu = (props: EditorMenuProps) => {
    const {
        mediaKey,
        isMain,
        onClickMenuItemHandler,
        deleteImageHandler,
        onClickChangeMediaHandler,
        changeMainImage,
        state
    } = props


    const {t}=useTranslation()
    const [anchorEl, setAnchorEl] = React.useState<null | AnchorState>(null);

    const open = Boolean(anchorEl && anchorEl[mediaKey]);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl({[`${mediaKey}`]: event.currentTarget});
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <IconButton
                className={styles.container}
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleOpen}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="basic-menu"
                open={open}
                anchorEl={anchorEl && anchorEl[mediaKey]}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                onClick={handleClose}
            >
                <MenuItem
                    onClick={(e: React.MouseEvent) => onClickMenuItemHandler(e, () => deleteImageHandler(e, state.medias[mediaKey].id as string))}>{t('delete')}</MenuItem>
                <MenuItem
                    onClick={(e: React.MouseEvent) => onClickMenuItemHandler(e, () => onClickChangeMediaHandler(mediaKey, isMain))}>{t('change')}</MenuItem>
                {!isMain &&
                    <MenuItem
                        onClick={(e: React.MouseEvent) => onClickMenuItemHandler(e, () => changeMainImage(mediaKey))}>{t('makeMain')}</MenuItem>}
            </Menu>
        </>
    )
}

export default EditorMenu