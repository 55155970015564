import {FaAngleLeft, FaAngleRight, FaPhone, FaPhoneAlt, FaTimes} from "react-icons/fa";
import {IoCloseSharp} from "react-icons/io5";

import styles from './Navbar.module.css'
import {OpenSooqLogo} from '../../../assets/images'
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import PostContext from "../../../storage/PostContext";
import UIContext from "../../../storage/UIContext";
import {useNavigate} from "react-router-dom";
import {useHttp} from "../../../hooks";
import DynamicObject from "../../../models/dynamic-object";
import previousApi from "../../../api/previous.api";
import LanguageContext from "../../../storage/LanguageContext";
import {Args} from "../../../hooks/use-http/interfaces";
import {AxiosResponse} from "axios";
import {MediaMapper} from "../../../helpers/media.functions";
import stepModule from "../../Flow/Steps/Step.module.css";
import {getFinalPayload, getSizes, isIFrame} from "../../../helpers/functions";
import Previous from "../../UI/Previous/Previous";
import {IFrameEvents} from "../../../IFrameEvents";

const Navbar = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const urlParams = new URLSearchParams(window.location.search)

    const {t} = useTranslation();
    const postCtx = useContext(PostContext)
    const uiCtx = useContext(UIContext)
    const navigate = useNavigate()
    const previousHttp = useHttp<DynamicObject>({
        callback: previousApi,
        initialData: {}
    })
    const langCtx = useContext(LanguageContext)

    const currentStepData: DynamicObject = {}

    const request = previousHttp.request


    previousHttp.request = function (args?: Args) {

        uiCtx.setLoading(true)
        const data: DynamicObject = {
            draftId: postCtx.data.draft_id,
            workflowId: postCtx.data.previous?.workflow_id,
            currentStep: postCtx?.data?.previous?.step_identifier,
            flow_type: postCtx.data.flow_type == 'cv' ? 'cv' : 'add-post'
        }


        const oldSuccess = args?.callbacks?.success

        const onSuccess = (response: AxiosResponse) => {
            postCtx.updateData({
                workflow_id: response.data.workflow_id,
                draft_id: response.data.draft.id,
                step: response.data.step,
                start_time: Date.now(),
                previous: response.data.previous,
                media: MediaMapper(response.data.media),
            })
            const mediaResult = MediaMapper(response.data.media)

            if (response.data.step?.identifier.includes('CV')) {

                postCtx.updateData({
                    form: {
                        ...data,
                        ...response.data.draft.payload
                    },
                    media: mediaResult
                })
            } else {
                const finalPayload = getFinalPayload(response.data.draft.payload)
                postCtx.updateData({
                    form: {
                        ...data,
                        ...finalPayload
                    },
                    media: mediaResult
                })
            }

            uiCtx.setLoading(false)
        }

        request({
            ...args,
            config: {
                ...args?.config,
                data: {
                    ...args?.config?.data,
                    ...data
                },
                lang: "en"
            },
            callbacks: {
                ...args?.callbacks,
                success: onSuccess
            },

        })
    }

    function callPreviousApi() {
        previousHttp.request(
            {
                config: {
                    data: {
                        draftId: postCtx.data.draft_id,
                        workflowId: postCtx.data.previous?.workflow_id,
                        currentStep: postCtx?.data?.previous?.step_identifier,
                    },
                },
            }
        )
    }

    const onPreviousHandler = () => {
        callPreviousApi();
    }


    const backEventHandler = () => {

        if (postCtx.data.previous) {
            callPreviousApi();
        }
    }


    const addBackEventListener = () => {

        if (isIFrame()) {
            const listener = (e) => {
                if (e.data?.action === "on-previous") {
                    backEventHandler()
                }
            }
            window.addEventListener('message',listener, false)
            return () => {
                window.removeEventListener('message', listener, false)
            }
        }

        window.addEventListener("on-previous", backEventHandler)
        return () => {
            window.removeEventListener("on-previous", backEventHandler)
        }
    }


    // useEffect(addBackEventListener, [postCtx.data]);


    window.onpopstate = (e) => {
        e.preventDefault()
        // window.dispatchEvent(new CustomEvent("on-previous"))
    }

    useEffect(() => {
        if (width <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [width]);

    const from = urlParams.get('from') ?? ''


    if (["my-opensooq", "opensooq"].includes(from)) {
        return <></>
    }

    const titleStyle = {
        fontWeight: postCtx.data?.step?.title?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(postCtx.data?.step?.title?.style?.size),
        color: postCtx.data?.step?.title?.color,
        marginTop: "5px"
    } as React.CSSProperties


    return (
        <header className={styles.container}>
            <div className={styles.items}>
                {!postCtx?.data?.previous && !isMobile && <div></div>}
                {!postCtx?.data?.previous && isMobile && <div className={styles.item}>
                    <img src={OpenSooqLogo} alt="Opensooq"/>
                </div>}

                {
                    postCtx?.data?.previous &&
                    <Previous onClickPreviousHandler={onPreviousHandler} withTitle={true}/>
                }

                {!isMobile && <div style={{position: "absolute", top: "25px", left: "45%"}} className={styles.item}>
                    <img src={OpenSooqLogo} alt="Opensooq"/>
                </div>
                }
                <div className={styles.phoneCloseIcon_container}>
                    {isMobile && <>
                        <div className={`${styles.item} ${styles.phone}`}>
                            <FaPhoneAlt style={{
                                color: "#0179FF"
                                , marginTop: "-5px"
                            }} className={styles.icon}/>
                        </div>
                    </>}
                    {!isMobile && langCtx.language == 'en' && <div className={`${styles.item} ${styles.phone}`}>
                        <FaPhoneAlt style={{height: "15px", width: "15px", marginTop: "-5px", color: "#0179FF"}}
                                    className={styles.icon}/>
                        <span style={{fontSize: "16px"}}>07800000000</span>
                    </div>}
                    {!isMobile && langCtx.language == 'ar' && <div className={`${styles.item} ${styles.phone}`}>
                        <span style={{fontSize: "16px"}}>07800000000</span>
                        <FaPhoneAlt style={{height: "15px", width: "15px", marginTop: "-5px", color: "#0179FF"}}
                                    className={styles.icon}/>
                    </div>}
                    <div><IoCloseSharp style={{height: "25px", width: "25px", marginTop: "3px"}}
                                       className={styles.icon}/>
                    </div>


                </div>

            </div>
        </header>
    )
}

export default Navbar