import React, {useContext, useEffect, useState} from "react";
import {MediaStepProps} from "./interface";
import styles from './MediaStep.module.css'
import Media from "../../../UI/MediaUpload/Media";
import {getSizes} from "../../../../helpers/functions";
import PostContext from "../../../../storage/PostContext";
import {StateDataType} from '../../../UI/MediaUpload/MediaUpload.interfaces'



const MediaSection = (props: MediaStepProps): JSX.Element => {

    const {config} = props



    /************************************
     *            CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)

    /************************************
     *            STATE
     * *********************************/
    const [mediaState, setMediaState] = useState<StateDataType|null>(null)


    /*-------------------------------------
     *            FUNCTIONS
     * ----------------------------------*/

    /************************************
     *         STATE HANDLER
     * *********************************/
    const onChangeMedia = (state: StateDataType) => {
        setMediaState(state)
    }

    /*---------------------------------------------------------------*/

    useEffect(function (){


        if (mediaState){
            const media = {...postCtx.data.media}
            media[config.identifier] = {
                ...mediaState
            }

            // @ts-ignore
           const data = props.loadData()

            postCtx.updateData({
                media: media,
                form: {
                    ...postCtx.data.form,
                    ...data
                }
            })
            setMediaState(null)
        }

    }, [mediaState])

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>{config.title?.locale}</h1>
                <ul>
                    <li><span style={{marginTop:"5px",fontWeight:config.subTitle?.style?.bold?"bold":"normal",fontSize:getSizes(config.subTitle?.style?.size),color:config.subTitle?.color}}>{config.subTitle?.locale}</span></li>
                </ul>
            </div>
            <div className={styles.content}>
                <Media identifier={config.identifier} onChangeMedia={onChangeMedia} />
            </div>

        </div>
    )
}



export default MediaSection