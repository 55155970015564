import {useHttp} from "./hooks";
import './helpers/index'
import {AxiosResponse} from "axios";
import {Navbar} from "./components/Layout";
import UIContext from "./storage/UIContext";
import PostContext from "./storage/PostContext";
import {ComponentLoader, DisconnectInternet} from "./components/UI";
import './Master.css';
import './Bootstrap.css'
import MapperFlow from "./components/MapperFlow";
import {Args} from "./hooks/use-http/interfaces";
import './helpers/index'
import LanguageContext from "./storage/LanguageContext";
import React, {useContext, useEffect, useState} from 'react';
import DynamicObject from "./models/dynamic-object";
import {ContentContainer} from "./components/Containers/index";
import {arSA} from '@mui/material/locale';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import './i18n';
import {useNavigate} from "react-router-dom";
import EditPost from "./components/Flow/EditPost/EditPost";
import JobApply from "./components/Flow/JobApply/JobApply";
import {getFinalPayload, getUrlBasedOnCountry, isIFrame, parentCallBack} from "./helpers/functions";
import {MediaMapper} from "./helpers/media.functions";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import Draft from "./components/Flow/Draft/Draft";
import Activate from "./components/Flow/Activate/Activate";
import Republish from "./components/Flow/Republish/Republish";
import Mycv from "./components/Flow/Mycv/Mycv";
import {
    configInitApi,
    configNextApi,
    configJobApplyApi,
    configDraftApi,
    configActivateApi,
    configRepublishApi,
    configMyCvApi, configPreviousApi
} from './config/api.config'
import Previous from "./components/UI/Previous/Previous";

import {OK, PAGE_NOT_FOUND, REFRESH_PAGE} from "./constants/Status";
import {IFrameEvents} from "./IFrameEvents";
import i18n from "./i18n";
import MediasCacher from "./models/medias-cacher";
import {getDataStorage} from "./helpers/storage.helper";


function App() {


    /************************************
     *              CONSTANT
     * *********************************/
    /*CONTEXT*/
    const postCtx = useContext(PostContext)
    const uiCtx = useContext(UIContext)
    const langCtx = useContext(LanguageContext)
    /*URL PARAMS*/
    const urlParams = new URLSearchParams(window.location.search)
    const flow = urlParams.get('flow') || "add"
    const from = urlParams.get('from') ?? ''
    /*NAVIGATE*/
    const navigate = useNavigate()
    /*THEME*/
    const theme = createTheme({palette: {primary: {main: '#1976d2'},},}, arSA,);

    /************************************
     *              STATES
     * *********************************/
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [width, setWidth] = useState<number>(window.innerWidth);


    /*---------------------------------------------------------------
     *                       HTTP REQUEST
     * --------------------------------------------------------------*/

    /************************************
     *         INIT REQUEST API
     * *********************************/
    const initApi = useHttp<DynamicObject>(configInitApi)
    const onSuccessInitApi = (response: AxiosResponse) => {
        parentCallBack('without_confirm_dialog')
        postCtx.updateStep({
            workflow_id: response.data.workflow_id,
            draft_id: response.data.draft.id,
            step: response.data.step,
            start_time: Date.now(),
            previous: response.data.previous
        })
    }


    /************************************
     *         NEXT REQUEST API
     * *********************************/
    const nextApi = useHttp<DynamicObject>(configNextApi)
    const request = nextApi.request
    nextApi.request = function (args?: Args) {
        const data: DynamicObject = {
            draftId: postCtx.data.draft_id,
            time_spent_ms: Date.now() - postCtx.data.start_time,
            workflowId: postCtx.data.workflow_id,
            currentStep: postCtx?.data?.step?.identifier,
        }

        for (const key in postCtx.data.currentStepData) {
            data[key as keyof typeof data] = postCtx.data.form[key as keyof typeof postCtx.data.form]
        }

        request({
            ...args,
            config: {
                flow_type: postCtx.data.flow_type,
                ...args?.config,
                data: {
                    ...data,
                    ...args?.config?.data,
                },
                language: langCtx.language
            },
            callbacks: {
                ...args?.callbacks,
                before: () => {
                    uiCtx.setLoading(true)
                }
            }
        })
    }


    /************************************
     *         JOB APPLY REQUEST API
     * *********************************/
    const jobApplyHttp = useHttp<DynamicObject>(configJobApplyApi)

    const requestTakeActionJobApply = jobApplyHttp.request

    jobApplyHttp.request = (args) => {
        const success = (response: AxiosResponse) => {
            parentCallBack('without_confirm_dialog')
            const finalPayload = getFinalPayload(response.data.draft.payload)
            postCtx.updateData({
                form: {
                    ...postCtx.data.form,
                    ...finalPayload,
                },
                step: {
                    ...response.data.step
                },
                draft_id: response.data.draft.id,
                workflow_id: response.data.workflow_id,
                media: MediaMapper(response.data.media),
            })
        }

        requestTakeActionJobApply({
            ...args,
            config: {
                post_id: parseInt(urlParams.get('post_id')),
            },
            callbacks: {
                ...args?.callbacks,
                success: success,
            }
        })
    }


    /************************************
     *         DRAFT REQUEST API
     * *********************************/
    const draftHttp = useHttp<DynamicObject>(configDraftApi)


    const requestTakeActionDraft = draftHttp.request

    draftHttp.request = (args) => {

        const success = (response: AxiosResponse) => {
            const finalPayload = getFinalPayload(response.data.draft.payload)
            postCtx.updateData({
                form: {
                    ...postCtx.data.form,
                    ...finalPayload,
                },
                step: {
                    ...response.data.step
                },
                draft_id: response.data.draft.id,
                workflow_id: response.data.workflow_id,
                media: MediaMapper(response.data.media),
                previous: response.data.previous

            })
            const mediaResult = MediaMapper(response.data.media)
            MediasCacher.getInstance().concat(mediaResult)
        }

        requestTakeActionDraft({
            ...args,
            config: {
                draft_id: urlParams.get('draft_id'),
            },
            callbacks: {
                ...args?.callbacks,
                success: success,
            }
        })
    }


    /************************************
     *         ACTIVATE REQUEST API
     * *********************************/
    const activateHttp = useHttp<DynamicObject>(configActivateApi)

    const requestTakeActionActivate = activateHttp.request

    activateHttp.request = (args) => {

        const success = (response: AxiosResponse) => {
            const finalPayload = getFinalPayload(response.data.draft.payload)
            postCtx.updateData({
                form: {
                    ...postCtx.data.form,
                    ...finalPayload,
                },
                step: {
                    ...response.data.step
                },
                draft_id: response.data.draft.id,
                workflow_id: response.data.workflow_id,
                media: MediaMapper(response.data.media),
                post_id: parseInt(urlParams.get('post_id'))

            })
            parentCallBack(REFRESH_PAGE)

        }

        requestTakeActionActivate({
            ...args,
            config: {
                post_id: parseInt(urlParams.get('post_id')),
            },
            callbacks: {
                ...args?.callbacks,
                success: success,
            }
        })
    }


    /************************************
     *         REPUBLISH REQUEST API
     * *********************************/
    const republishHttp = useHttp<DynamicObject>(configRepublishApi)

    const requestTakeActionRepublish = republishHttp.request

    republishHttp.request = (args) => {

        const success = (response: AxiosResponse) => {
            const finalPayload = getFinalPayload(response.data.draft.payload)
            postCtx.updateData({
                form: {
                    ...postCtx.data.form,
                    ...finalPayload,
                },
                step: {
                    ...response.data.step
                },
                draft_id: response.data.draft.id,
                workflow_id: response.data.workflow_id,
                media: MediaMapper(response.data.media),
            })
            const mediaResult = MediaMapper(response.data.media)
            MediasCacher.getInstance().concat(mediaResult)
            parentCallBack(REFRESH_PAGE)

        }

        requestTakeActionRepublish({
            ...args,
            config: {
                post_id: parseInt(urlParams.get('post_id')),
            },
            callbacks: {
                ...args?.callbacks,
                success: success,
            }
        })
    }


    /************************************
     *         MY CV  REQUEST API
     * *********************************/
    const myCvHttp = useHttp<DynamicObject>(configMyCvApi)

    const requestTakeActionMyCv = myCvHttp.request

    myCvHttp.request = (args) => {

        const success = (response: AxiosResponse) => {
            const finalPayload = getFinalPayload(response.data.draft.payload)
            postCtx.updateData({
                form: {
                    ...postCtx.data.form,
                    ...finalPayload,
                },
                step: {
                    ...response.data.step
                },
                draft_id: response.data.draft.id,
                workflow_id: response.data.workflow_id,
                media: MediaMapper(response.data.media),
            })
        }

        requestTakeActionMyCv({
            ...args,
            config: {},
            callbacks: {
                ...args?.callbacks,
                success: success,
            }
        })
    }

    /************************************
     *       PREVIOUS REQUEST API
     * *********************************/
    const previousHttp = useHttp<DynamicObject>(configPreviousApi)

    const requestTakeActionPrevious = previousHttp.request

    previousHttp.request = function (args?: Args) {

        uiCtx.setLoading(true)
        const data: DynamicObject = {
            draftId: postCtx.data.draft_id,
            workflowId: postCtx.data.previous?.workflow_id,
            currentStep: postCtx?.data?.previous?.step_identifier,
            flow_type: postCtx.data.flow_type == 'cv' ? 'cv' : 'add-post'
        }


        const onSuccess = (response: AxiosResponse) => {
            postCtx.updateData({
                workflow_id: response.data.workflow_id,
                draft_id: response.data.draft.id,
                step: response.data.step,
                start_time: Date.now(),
                previous: response.data.previous,
                media: MediaMapper(response.data.media),
            })
            const mediaResult = MediaMapper(response.data.media)

            if (response.data.step?.identifier.includes('CV')) {
                postCtx.updateData({
                    form: {
                        ...data,
                        ...response.data.draft.payload
                    },
                    media: mediaResult
                })
            } else {
                const finalPayload = getFinalPayload(response.data.draft.payload)
                postCtx.updateData({
                    form: {
                        ...data,
                        ...finalPayload
                    },
                    media: mediaResult
                })
            }
            uiCtx.setLoading(false)
        }

        requestTakeActionPrevious({
            ...args,
            config: {
                ...args?.config,
                data: {
                    ...args?.config?.data,
                    ...data
                },
                lang: "en"
            },
            callbacks: {
                ...args?.callbacks,
                success: onSuccess
            },

        })
    }

    /*---------------------------------------------------------------*/

    /************************************
     *         EVENT LISTENER
     * *********************************/

    const handleOnlineStatusChange = () => {
        if (!uiCtx.isOnline)
            uiCtx.setOnline(true)
    }
    const handleOfflineStatusChange = () => {
        if (uiCtx.isOnline)
            setTimeout(() => {
                uiCtx.setOnline(false)
            }, 3000)
    }
    window.addEventListener('online', handleOnlineStatusChange);

    window.addEventListener('offline', handleOfflineStatusChange);


    /*---------------------------------------------------------------
    *                       EFFECTS
    * --------------------------------------------------------------*/

    useEffect(() => {
        return () => {
            window.removeEventListener('online', handleOnlineStatusChange);
            window.removeEventListener('offline', handleOfflineStatusChange);
        };
    }, [uiCtx.isOnline])

    useEffect(() => {
        if (postCtx.data?.step?.identifier && flow === "add") {
            urlParams.set('step', postCtx.data?.step?.identifier)
            const params = urlParams.toString() ? `?${urlParams.toString()}` : ''
            navigate(`${window.location.pathname}${params}`)
            if (isIFrame())
                IFrameEvents.dispatch("push-history-state", {params})

        }

    }, [postCtx.data?.step?.identifier])


    useEffect(() => {
        prepareRequest()
    }, [width])

    useEffect(() => {
        if (width <= 768) {
            uiCtx.setMobile(true)
        } else {
            uiCtx.setMobile(false)
        }
    }, [width]);

    useEffect(() => {
        setIsLoggedIn(true);
        // window.addEventListener('resize', () => {
        //     setWidth(window.innerWidth);
        // });

        const returnUrl = urlParams.get('return')??getUrlBasedOnCountry(getDataStorage().country);
        window['returnUrl'] = returnUrl

        return () => {
            // window.removeEventListener('resize', () => {
            //     setWidth(window.innerWidth);
            // });
        }
    }, [])


    /*---------------------------------------------------------------
    *                       FUNCTIONS
    * --------------------------------------------------------------*/


    const prepareRequest = () => {
        switch (flow) {
            case "add": {
                initApi.request({
                    config: {
                        language: langCtx.language
                    },
                    callbacks: {
                        success: onSuccessInitApi
                    },
                })
                break
            }
            case "jobApply": {
                if (urlParams.get('post_id') == null) {
                    parentCallBack(PAGE_NOT_FOUND)
                    return
                }
                jobApplyHttp.request()
                break
            }
            case "draft": {
                if (urlParams.get('draft_id') == null) {
                    parentCallBack(PAGE_NOT_FOUND)
                    return
                }
                draftHttp.request()
                break
            }
            case "activate": {
                if (urlParams.get('post_id') == null) {
                    parentCallBack(PAGE_NOT_FOUND)
                    return
                }
                activateHttp.request()
                break
            }
            case "republish": {
                if (urlParams.get('post_id') == null) {
                    parentCallBack(PAGE_NOT_FOUND)
                    return
                }
                republishHttp.request()
                break
            }
            case "my-cv": {
                myCvHttp.request()
                break
            }
        }
    }

    const onClickPreviousHandler = () => {
        previousHttp.request(
            {
                config: {
                    data: {
                        draftId: postCtx.data.draft_id,
                        workflowId: postCtx.data.previous?.workflow_id,
                        currentStep: postCtx?.data?.previous?.step_identifier,
                    },
                },
            }
        )
    }

    const PreviousComponent = () => {
        return (from === 'my-opensooq' || from === 'opensooq') && postCtx?.data?.previous &&
            <Previous withTitle={true} onClickPreviousHandler={onClickPreviousHandler}/>
    }


    const Content = () => {
        return <>
            {uiCtx.isLoading && <ComponentLoader/>}
            {postCtx.data?.step && !uiCtx.isLoading && flow === "add" &&
                <MapperFlow config={postCtx.data.step} nextApi={nextApi} Previous={PreviousComponent}/>}
            {flow === "edit" && <EditPost/>}
            {flow === "jobApply" && <JobApply/>}
            {flow === "draft" && <Draft Previous={PreviousComponent}/>}
            {flow === "activate" && <Activate/>}
            {flow === "republish" && <Republish/>}
            {flow === "my-cv" && <Mycv/>}
        </>
    }
    Sentry.init({
        dsn: process.env.REACT_APP_DSN,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
    return (
        <ThemeProvider theme={theme}>
            <div className="App">

                {isLoggedIn && uiCtx.isOnline && <>
                    <Navbar/>
                    <ContentContainer>
                        <PreviousComponent/>
                        <Content/>
                    </ContentContainer></>}
                {!uiCtx.isOnline && <DisconnectInternet></DisconnectInternet>}
            </div>
        </ThemeProvider>
    );
}

export default App;

