import React, {useEffect} from "react";
import styles from './Modal.module.css'
import Overlay from "../Overlay/Overlay";
import {IoIosClose} from "react-icons/io";
import { TextProperty} from "../../../models/general";

interface Props {
    children?: React.ReactNode;
    title?: TextProperty | undefined,
    setIsOpen: (value: any) => void,
    footer?:React.ReactNode,
}

const Modal = (props: Props) => {
    const {title, setIsOpen} = props


    const closeHandler = () => {
            setIsOpen(false)
    }

    useEffect(() => {
        document.body.classList.add(`${styles.overflowHidden}`)
        return () => {
            document.body.classList.remove(`${styles.overflowHidden}`)
        }
    }, [])

    return (
        <Overlay>
            <div className={styles.container} onClick={closeHandler}>
                <div className={styles.model} onClick={e => e.stopPropagation()}>
                    <div className={styles.header}>
                        <h3 style={{color:title?.color}}>{title?.locale}</h3>
                        <span><IoIosClose className={"cursor__pointer"} style={{height: "25.2px", width: "25.85px"}} onClick={closeHandler}/></span>
                    </div>
                    <div className={styles.content}>
                        {props.children}
                    </div>
                        {props.footer}
                </div>
            </div>
        </Overlay>
    )
}

export default Modal