import React, {useEffect} from "react";
import {MediaStep as MediaStepModel} from "../../../../models";
import DynamicObject from "../../../../models/dynamic-object";
import {UseHttpResponse} from "../../../../hooks/use-http/interfaces";

interface VideoStepProps {
    config: MediaStepModel;
    nextApi?: UseHttpResponse<DynamicObject>,
}


const VideoStep = (props: VideoStepProps): JSX.Element => {
    const {config, nextApi} = props

    useEffect(function () {
        props.nextApi?.request()
    }, [])
    return (
        <div></div>
    )
}

export default VideoStep