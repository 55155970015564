import ArachnaApi from './requests/arachna-api'
import {AxiosInstance, AxiosProgressEvent, AxiosResponse} from "axios";
import DynamicObject from "../models/dynamic-object";
import ArbokApi from "./requests/arbok-api";
import {appConfig} from "../config";
import ActiveRequests from "../models/active-requests";
import Cookies from "js-cookie";


export default (config?: DynamicObject): Promise<AxiosResponse> => {
    // const desktopNumber = Math.floor(Math.random() * (10 - 1 + 1)) + 1
    // return ArbokApi.getInstance().request.post(`originals/media/desktop-${desktopNumber}`, config?.data, {
    //     onUploadProgress: (progressEvent: AxiosProgressEvent) => {
    //         const total = progressEvent?.total ? progressEvent.total : 0
    //         const progress = (progressEvent.loaded / total) * 50;
    //
    //         config?.onUploadProgress(progress)
    //     },
    //     onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
    //         const total = progressEvent?.total ? progressEvent.total : 0
    //         const progress = 50 + (progressEvent.loaded / total) * 50;
    //         config?.onDownloadProgress(progress)
    //     },
    //     params: config?.params
    // })
    const desktopNumber = Math.floor(Math.random() * (10 - 1 + 1)) + 1
    ActiveRequests.getInstance().increase()
    const key = Cookies.get('source') || "desktop"
    return new Promise((resolve, reject) => ArbokApi.getInstance().request.put(`/originals/media/${key}-${desktopNumber}`, config?.data, {
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                const total = progressEvent?.total ? progressEvent.total : 0
                const progress = (progressEvent.loaded / total) * 50;
                config?.onUploadProgress(progress)

            },
            onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
                const total = progressEvent?.total ? progressEvent.total : 0
                const progress = 50 + (progressEvent.loaded / total) * 50;
                config?.onDownloadProgress(progress)
            },
            params: config?.params,
        }).then((response: AxiosResponse) => {
            ActiveRequests.getInstance().decrease()
            resolve(response)
        })
    )


    //
    // return ArachnaApi.getInstance().request.post(process.env.REACT_APP_ARACHNA_API_URL + '/v2.1/payment/upload-media', config?.data, {
    //
    //     onUploadProgress: (progressEvent: AxiosProgressEvent) => {
    //         const total = progressEvent?.total ? progressEvent.total : 0
    //         const progress = (progressEvent.loaded / total) * 50;
    //         config?.onUploadProgress(progress)
    //     },
    //     onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
    //         const total = progressEvent?.total ? progressEvent.total : 0
    //         const progress = 50 + (progressEvent.loaded / total) * 50;
    //         config?.onDownloadProgress(progress)
    //     },
    //     headers: config?.headers,
    //     params: config?.params,
    // })


}