import {Props} from "./interface";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import styles from './ListCellOptions.module.css'
import UIContext from "../../../../storage/UIContext";
import PostContext from "../../../../storage/PostContext";
import DynamicObject from "../../../../models/dynamic-object";
import useSearch from "../../../../hooks/use-search/use-search";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import languageContext from "../../../../storage/LanguageContext";
import {getImgPath, getSizes} from "../../../../helpers/functions";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";


const ListCellOptions = React.forwardRef<RefDataType<string>, Props>((props, ref) => {
        const {config} = props

        /*-------------------------------------
         *              HOOKS
         * ----------------------------------*/

        /************************************
         *   SET & GET VALIDATION AND STATES
         * *********************************/
        const {state, validation} = useDataPayload<string | number>({
            ref,
            config: props.config
        })

        /************************************
         *      CUSTOME SEARCH HOOK
         * *********************************/
        const {filteredResults, Search} = useSearch({
            items: config.values,
            search: {
                placeholder: config?.search?.placeholder?.locale
            }
        })

        /************************************
         *          TRANSLATION
         * *********************************/

        const {t} = useTranslation();

        /************************************
         *            CONTEXT
         * *********************************/
        const langCtx = useContext(languageContext)
        const postCtx = useContext(PostContext)
        const uiCtx = useContext(UIContext)


        /************************************
         *            CONTEXT
         * *********************************/
        const labelStyle = {
            fontWeight: config.cell.label.style?.bold ? "bold" : "normal",
            fontSize: getSizes(config.cell.label.style?.size),
            color: config.cell.label.color,
        } as React.CSSProperties


    const oldValue = postCtx.data.form[config.identifier]

        /*-------------------------------------
         *            FUNCTIONS
         * ----------------------------------*/

        /************************************
         *        CHECK LINKED
         * *********************************/
        const checkLinked = () => {
            // if (!config.linked?.length) {
            //     return
            // }
            // const data: DynamicObject = {...postCtx.data}
            //
            // for (var i = 0, len = config.linked?.length; i < len; i++) {
            //     delete data[`${config.linked[i]}`]
            // }
            // postCtx.updateData(data)
            // const previewConfig: DynamicObject = postCtx.previewStep
            // const lastPreview = previewConfig.childs.filter((child: any) => {
            //     if (config.linked?.includes(child.identifier)) {
            //         delete child.values
            //         child.values = []
            //         return {
            //             ...child
            //         }
            //     } else {
            //         return child
            //     }
            // })
            //
            // const finalConfig = {
            //     ...previewConfig,
            //     childs: lastPreview
            // }
            // props.setConfigData(finalConfig);


            if ( props.selectFieldConfig.linked?.length) {

                const linkedData = {
                    // ...postCtx.data.form,
                    // ...props.tempFormData,
                    // [`${config.identifier}`]: postCtx.data.form[config.identifier]
                }

                for (const field of props.selectFieldConfig.linked)
                    linkedData[field] = ''

                const previewConfig: DynamicObject = postCtx.previewStep
                const lastPreview = previewConfig.childs.filter((child: any) => {
                    if (props.selectFieldConfig.linked?.includes(child.identifier)) {
                        delete child.values
                        child.values = []
                        return {
                            ...child
                        }
                    } else {
                        return child
                    }
                })

                const finalConfig = {
                    ...previewConfig,
                    childs: lastPreview
                }

                props.setConfigData(finalConfig);

                // postCtx.updateData({
                //     form: {...form},
                //     previewStep: finalConfig
                // })

                return linkedData
            }

        }

        /************************************
         * UPDATE ON CONTEXT + NEXT API
         * *********************************/
        const addItemHandler = (id: number | string, label: string) => {
            state.set(id)

            uiCtx.setErrors([])

            const previewConfig: DynamicObject = postCtx.previewStep
            const lastPreview = previewConfig.childs.filter((child: any) => {
                if (child.identifier === config.identifier) {
                    const values = child.values
                    values[0] = {...values[0], label: label, id: id}
                    return {
                        ...child,
                        values: [...values]
                    }
                } else {
                    return child
                }
            })

            const finalConfig = {
                ...previewConfig,
                childs: lastPreview
            }

            props.setConfigData(finalConfig);

            const linkedData = checkLinked()
            const formData = {
                ...postCtx.data.form,
                ...props.tempFormData,
                ...linkedData,
                [config.identifier]: id
            }

            postCtx.updateData({
                form: {
                    ...formData,
                }
            })
        }

        return (
            <div className={styles.container}>
                {/* SEARCH */}
                {config?.search &&
                    <Search></Search>
                }
                {/* ITEMS */}
                <div className={styles.items_container}>
                    {
                        filteredResults.length < 1 && (
                            <div className={`row ${styles.no_found_data}`}>
                                <h3>
                                    {t('noResults')}
                                </h3>
                            </div>
                        )
                    }
                    {
                        filteredResults.map(item => {
                                if (item.id) {
                                    return (
                                        <div key={item.id} className={`row ${styles.item}`}
                                             onClick={(e: React.MouseEvent<HTMLElement>) => addItemHandler(item.id, item.label)}>
                                            <div className={`col-md-9 col-10 ${styles.image_text}`}>
                                                {/*ITEM ICON*/}
                                                {

                                                    item.icon && (
                                                        <div className={styles.image_container}>
                                                            <img className={styles.image}
                                                                 style={{width: config.identifier.includes("categoriesMain") ? "50px" : "30px"}}
                                                                 src={getImgPath(item.icon)}/>
                                                        </div>
                                                    )
                                                }


                                                {/*ITEM LABEL*/}
                                                <div className={styles.text}>
                                                    <h1 style={labelStyle}>{item.label}</h1>
                                                </div>
                                            </div>
                                            {/*ITEM ARROW */}
                                            <div className={`col-md-3 col-2 ${styles.icon}`}>
                                                {(langCtx.language == 'en') ?
                                                    <IoIosArrowForward style={{height: "21.2px", width: "22.85px"}}/> :
                                                    <IoIosArrowBack style={{
                                                        height: "21.2px",
                                                        width: "22.85px"
                                                    }}/>}                                        </div>
                                        </div>
                                    )
                                }
                                return (
                                    <h3 style={{textAlign: "center"}}>{item.label}</h3>
                                )
                            }
                        )
                    }
                </div>
                <ValidationError validation={validation}/>

            </div>
        )
    }
)
export default ListCellOptions