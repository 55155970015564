import {StateDataType as Media} from "../components/UI/MediaUpload/MediaUpload.interfaces";
import DynamicObject from "./dynamic-object";

export interface MediaResult {
    [key: string]: Media
}


export default class MediasCacher {
    private _medias: MediaResult
    static instance: MediasCacher


    private constructor() {
        this._medias = {}
    }

    static getInstance() {
        if (!this.instance)
            this.instance = new MediasCacher()

        return this.instance
    }


    fireEvent() {
        window.dispatchEvent(new CustomEvent("update-media", {
            detail: {
                medias: this._medias
            }
        }))
    }

    get(id: string) {
        if (!this._medias[id])
            return

        return {
            ...this._medias[id],
            medias: {
                ...this._medias[id].medias
            }
        }

    }


    update(id: string, media: Media) {
        this._medias[id] = media
        console.log('update caching', Object.keys(this._medias))
        this.fireEvent()
    }


    concat(medias: MediaResult) {

        for (const key in medias) {
            const mediaDraft : DynamicObject = {}
            const mediaCache : DynamicObject = {}
            let mediasBuffer : DynamicObject = {}

            for (const fileKey in medias[key].medias) {
                mediaDraft[medias[key].medias[fileKey].uri] = {
                    ...medias[key].medias[fileKey],
                    hash: fileKey
                }
            }



            for (const fileKey in this._medias[key]?.medias) {
                mediaCache[this._medias[key].medias[fileKey].uri] = {
                    ...this._medias[key].medias[fileKey],
                    hash: fileKey
                }
            }


            mediasBuffer = {
                ...mediaCache
            }


            for (const uri in mediaDraft) {
                mediasBuffer[uri] = {
                    ...mediaCache[uri],
                    ...mediaDraft[uri],
                }
            }



            let mediaResult : DynamicObject = {}

            for (const uri in mediasBuffer) {
                const hash = mediasBuffer[uri].hash
                delete mediasBuffer[uri].hash
                mediaResult[hash] = {
                    ...mediasBuffer[uri]
                }
            }


            mediaResult = Object.keys(mediaResult).sort().reduce(
                (obj, key) => {
                    obj[key] = mediaResult[key];
                    return obj;
                },
                {}
            );



            this._medias[key] = {
                medias: {
                    ...mediaResult
                },
                main_media: medias[key].main_media
            }


        }


        this.fireEvent()
    }


}
