import React, {useEffect} from "react";
import {Props} from "./interface";
import styles from './ListTagsOptions.module.css'
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";

const ListTagsOptions = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    /*-------------------------------------
     *              HOOKS
     * ----------------------------------*/

    /************************************
     *   SET & GET VALIDATION AND STATES
     * *********************************/
    const {state, validation} = useDataPayload<string>({
        ref,
        config: props.config
    })

    /*----------------------------------------------------------------
     *                            FUNCTIONS
     * ---------------------------------------------------------------*/

    /************************************
     *        ACTION HANDLER
     * *********************************/
    const addItemHandler = (
        id: number,
    ) => {
        state.set(id.toString());

    };

    /*---------------------------------------------------------------*/

    /************************************
     *       SET INITIAL VALUE
     * *********************************/
    useEffect(function () {
        if (props.config?.values.length > 0 && !state.value) {
            addItemHandler(parseInt(props.config?.values[0]?.id) as number)
        }
    }, [props.config?.values])


    return (
        <div
            className={`${styles.container}`}>
            <div className={`${styles.tags_row}`}>
                {props.config?.values.map((value, index) => {
                    return (
                        <button key={index} name={value.label} onClick={(e) => addItemHandler(parseInt(value.id))}
                                className={parseInt(value.id) == parseInt(state.value as string) ? `${styles.customButton} ${styles.active}` : `${styles.customButton}`}>
                            {value.label}
                        </button>
                    )
                })}
            </div>
            <ValidationError validation={validation}/>
        </div>
    )
})
export default ListTagsOptions


