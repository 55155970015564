import {BsX} from "react-icons/bs";
import {FaSistrix} from "react-icons/fa";
import React, {ChangeEvent, useEffect, useState} from "react";
import {Config, Item, SearchProps} from './interfaces';
import {FormControl, Input, InputAdornment, OutlinedInput, TextField} from "@mui/material";
import {InputOutlined, InputRounded} from "@mui/icons-material";

const useSearch = (config: Config) => {
    const {items, search} = config
    const [query, setQuery] = useState<string | null>(null);
    const [focus, setFocus] = useState(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        if (!focus)
            setFocus(true)

    };

    const resetSearchField = (e: React.MouseEvent<SVGElement>) => {
        setFocus(false)
        setQuery(null);
    };


    const filteredResults = query !== null ? items.filter(
        (item: Item) => {
            return (
                item?.search_field.toLowerCase()
                    .includes(query.toLowerCase())
            );
        }
    ) : items;

    const Search = (searchProps: SearchProps) => {
        const {inputAttributes} = searchProps
        return (
            <div className={`row`}>
                <FormControl className='col-12' sx={{width: '100%'}} variant="outlined">
                    <Input
                        disableUnderline={true}
                        value={query}
                        endAdornment={query ?
                            <InputAdornment position="end"><BsX style={{cursor: "pointer"}} onClick={resetSearchField}
                                                                color={"#000"}></BsX></InputAdornment> : ""}
                        startAdornment={<InputAdornment position="start"><FaSistrix
                            color={"#000"}></FaSistrix></InputAdornment>}
                        placeholder={search?.placeholder}
                        style={{
                            marginBottom: "3px",
                            boxShadow: "0px 0px 20px rgb(0 0 0 / 7%)",
                            borderRadius: "8px",
                            height: "50px",
                            padding:"5px"
                        }}
                        onChange={handleChange}
                        autoFocus={query !== null}
                        onBlur={() => {
                            if (!query)
                                setQuery(null)
                        }}



                    />
                </FormControl>
            </div>
        );
    }

    return {
        filteredResults,
        Search
    }
}
export default useSearch;