import styles from './DateField.module.css';
import {Props, RefDataType} from "./interface";
import {getSizes} from "../../../../helpers/functions";
import PostContext from "../../../../storage/PostContext";
import languageContext from "../../../../storage/LanguageContext";
import {formatDate, toDateObject, fromDateObject} from './functions';
import React, {useContext, useImperativeHandle, useState} from "react";
import {Select, MenuItem, SelectChangeEvent, FormHelperText} from "@mui/material";
import useValidationNew from "../../../../hooks/use-validation/use-validation-new";

const DateField = React.forwardRef<RefDataType, Props>((props, ref) => {

    /************************************
     *            CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)
    const langCtx = useContext(languageContext)

    /*-------------------------------------
     *              HOOKS
     * ----------------------------------*/

    /************************************
     *      GET & CLEAR VALIDATION
     * *********************************/

    const {error, setValidationError, clearValidationError} = useValidationNew()


    /************************************
     *            CONSTANT
     * *********************************/

    const dateTimeMin = new Date()
    dateTimeMin.setDate(dateTimeMin.getDate() + props.config.minValue)
    const minYear = dateTimeMin.getFullYear()

    const dateTimeMax = new Date()
    dateTimeMax.setDate(dateTimeMax.getDate() + props.config.maxValue)
    const maxYear = dateTimeMax.getFullYear()

    const yearsComponents: Array<JSX.Element> = []
    const monthsComponents: Array<JSX.Element> = []
    const daysComponents: Array<JSX.Element> = []
    const alignCenter = (langCtx.language == 'en') ? 'left' : 'right'



    /*-------------------------------------
    *             FUNCTIONS
    * ----------------------------------*/

    /************************************
     *   SET CURRENT DATE IN DATE STATE
     *        AS INITIAL VALUE
     * *********************************/
    const getDate = () => {
        const dateTimeDefault = new Date()
        dateTimeDefault.setDate((new Date()).getDate() + props.config.defaultValue)
        return formatDate(dateTimeDefault)
    }

    /************************************
     *       SET CHOSEN DATE
     * *********************************/
    const addDateHandler = (e: SelectChangeEvent<number>, type: string) => {
        let dateAsObject = toDateObject(date)
        const newDate = {
            ...dateAsObject,
            [type]: Number(e.target.value)
        }
        setDate(fromDateObject(newDate))
        clearValidationError()

    }
    const [date, setDate] = useState<string>(postCtx.data.form[props.config.identifier] ==undefined  || postCtx.data.form[props.config.identifier] ==''  ? getDate : postCtx.data.form[props.config.identifier])

    useImperativeHandle<RefDataType, RefDataType>(ref, () => {
        return {
            getState: (): any => date,
            setValidationError
        }
    })

    const getDays = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    /************************************
     *       SET MENU ITEMS
     * *********************************/
    for (let year = minYear; year <= maxYear; year++) {
        yearsComponents.push(<MenuItem value={year}>{year}</MenuItem>)
    }

    for (let month = 1; month <= 12;month++) {
        monthsComponents.push(<MenuItem
            disabled={month > new Date().getMonth() + 1 && toDateObject(date).year == new Date().getFullYear() }
            value={month}>{month}</MenuItem>)
    }

    for (let day = 1; day <= getDays(toDateObject(date).year, toDateObject(date).month); day++) {
        daysComponents.push(<MenuItem
            disabled={day > new Date().getDate() + 1 && toDateObject(date).year == new Date().getFullYear() && toDateObject(date).month ==  new Date().getMonth()+1}
            value={day}>{day}</MenuItem>)
    }


    /************************************
     *     INLINE COMPONENT STYLE
     * *********************************/
    const containerStyle = {
        color: props.config.text.color.normal ?? 'black',
        fontWeight: props.config.text?.style?.bold ? 'bold' : 'normal',
        fontSize: getSizes(props.config.text?.style?.size)
    }


    return (
        <div className={`row justify-content-center ${styles.row}`}>
            <div className="">{props.config.text?.locale}</div>
            <div className={`col-12 ${styles.col} date`} style={containerStyle}>
                <Select
                    MenuProps={{PaperProps: {sx: {maxHeight: "20vh"}}}}
                    value={toDateObject(date).year}
                    onChange={(e: SelectChangeEvent<number>) => addDateHandler(e, 'year')}>
                    {yearsComponents}
                </Select>
                <Select
                    MenuProps={{PaperProps: {sx: {maxHeight: "20vh"}}}}
                    value={toDateObject(date).month}
                    onChange={(e: SelectChangeEvent<number>) => addDateHandler(e, 'month')}>
                    {monthsComponents}
                </Select>
                <Select
                    MenuProps={{PaperProps: {sx: {maxHeight: "20vh"}}}}
                    value={toDateObject(date).day}
                    onChange={(e: SelectChangeEvent<number>) => addDateHandler(e, 'day')}>
                    {daysComponents}
                </Select>
            </div>
            {error && <FormHelperText style={{
                color: "red",
                fontSize: "18px",
                paddingTop: "2px",
                textAlign: alignCenter
            }}>{`*   ${error}`}</FormHelperText>
            }
        </div>
    )

})

export default DateField