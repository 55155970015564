import React, {useContext, useEffect, useState} from "react";
import {Props,RefDataType} from './interfaces';
import styles from './PaymentWidget.module.css'
import PackageTypes from "../PackageTypes/PackageTypes";
import {fetchFirstProduct, fetchPackageConfig} from './functions';
import LanguageContext from "../../../../storage/LanguageContext";
import {useTranslation} from "react-i18next";
// import 'opensooq-services/src/assets/css/CreditPackageCol.css';
// import 'opensooq-services/src/assets/css/CustomButton.css';
// import 'opensooq-services/src/assets/css/DefaultPackageCol.css';
// import 'opensooq-services/src/assets/css/PackageBenefits.css';
// import 'opensooq-services/src/assets/css/PackageTypes.css';
// import 'opensooq-services/src/assets/css/PackageButton.css';
// import 'opensooq-services/src/assets/css/PackageGridItem.css';
// import 'opensooq-services/src/assets/css/PackageList.css';
// import 'opensooq-services/src/assets/css/PaymentWidget.css';
// import 'react-loading-skeleton/dist/skeleton.css';
// import 'opensooq-services/dist/esm/assets/css/Main.css'

import {PaymentWidget as PaymentWidgetBundle} from "opensooq-services";
import {generateToken} from "../../../../helpers/auth.helper";
import PostContext from "../../../../storage/PostContext";
import {getDataStorage} from "../../../../helpers/storage.helper";


const PaymentWidget = React.forwardRef<RefDataType, Props>((props, ref) => {

    const langCtx=useContext(LanguageContext)
    const packages = props.config.data.packages
    const product = fetchFirstProduct(packages)
    const [label, setLabel] = useState<string>(langCtx.language=="en"? packages[product][0].label_en:packages[product][0].label_ar)
    const packageConfig = fetchPackageConfig(packages)
    const {t} = useTranslation()
    const [token,setToken] = useState<string>('')
    const postCtx = useContext(PostContext)
    const storage = getDataStorage()
    useEffect(() => {
        generateToken().then((token) => {
            setToken(token ?? '');
        })
    },[])
    const isElas = Object.keys(packages).includes('Elas')

    const products =  Object.keys(packages)
    return token && <PaymentWidgetBundle referral_url={window['returnUrl']} products={products} domain={process.env.REACT_APP_DOMAIN_PAYMENT_API} api={process.env.REACT_APP_PAYMENT_API} token={token} source={storage.source} countryAbbreviation={storage.country} language={langCtx.language} modelId={postCtx.data.post_id}/>

    // return (
    //
    //     <div className={`${styles.box}`}>
    //         <div className={`row ${styles.row}`}>
    //             <div className={`col ${styles.col}`}>
    //                 {/*<h4>{label}</h4>*/}
    //                 <h4>{
    //                     isElas ? t('activateYourAdNow!') : t('doYouWantMoreViews?')
    //                 }</h4>
    //             </div>
    //         </div>
    //         <PackageTypes packageConfig={packageConfig} setLabel={setLabel} product={product}
    //                       bundles={props.config.data.bundle} packages={packages}/>
    //     </div>
    // )
})
export default PaymentWidget














