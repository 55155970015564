import {Package} from "../../../../models";

export function fetchFirstProduct(packages: {[key: string] : Array<Package>}){
    return Object.keys(packages)[0]
}
export function fetchPackageConfig(packages: {[key: string] : Array<Package>}){
    const packageNames = Object.keys(packages).reverse();
    return packageNames.map((value,index) => {
        if (packageNames.length - 1 === index)
            return {'padding': 'right', 'name': value}
        else if (index === 0)
            return {'padding': 'left', 'name': value}
        return {'padding': 'mid', 'name': value}
    })
}