import React from "react";
import {Props, RefDataType} from './interfaces';
import styles from './ButtonContainer.module.css'
import {getSizes} from "../../../../helpers/functions";
import {Button} from "../../index";

import * as flow from '../../index'
import DynamicObject from "../../../../models/dynamic-object";


const ButtonContainer = React.forwardRef<RefDataType, Props>((props, ref) => {

    const titleStyle = {
        fontWeight: props.config.title?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.title?.style?.size),
        color: props.config.title?.color,
    } as React.CSSProperties

    const subTitleStyle = {
        fontWeight: props.config.subTitle?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.subTitle?.style?.size),
        color: props.config.subTitle?.color,
    } as React.CSSProperties


    function ButtonComponent() {

        const typeName = props.config?.button?.type?.capitalize();
        const Component = flow[typeName as keyof typeof flow]
        const attributes: DynamicObject = {}

        if(!props.config.button || !Component)
            return <></>

        attributes.config = props.config.button
        attributes.key = props.config.button.identifier
        return <>
            {
                React.createElement(Component as React.FunctionComponent, {
                    ...attributes,
                })
            }
        </>;
    }


    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.box} shadow`}>
                <div className={`${styles.header}`}>
                    <h1 style={titleStyle}>{props.config.title?.locale}</h1>
                </div>
                <div className='row'>
                    <div className='col-md-12 mb-2 '>
                       <ButtonComponent />
                    </div>
                </div>
            </div>
        </div>
    )
})
export default ButtonContainer