import React, {useContext} from "react";
import {Props} from './interfaces';
import styles from './ListRadioOptions.module.css'
import DynamicObject from "../../../../models/dynamic-object";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import PostContext from "../../../../storage/PostContext";

const ListRadioOptions = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    const {state, validation} = useDataPayload<number | string>({
        ref,
        config: props.config
    })
    const {config, nextApi} = props

    const postCtx=useContext(PostContext)


    {/*ACTION HANDLER*/
    }
    const addItemHandler = (id: number | string) => {
        state.set(id)
        postCtx.addToForm(config.identifier, id)

        const httpConfig: DynamicObject = {
            config: {
                data: {
                    post: {}
                }
            }
        }
        httpConfig["config"]["data"][config.identifier] = id
        nextApi?.request(httpConfig)
    }

    const items = config?.values?.map((item, i) => {
        return (
            <div className={`${styles.item} cursor__pointer`}
                 onClick={(e: React.MouseEvent<HTMLElement>) => addItemHandler(item.id)}>
                <FormControlLabel control={<Radio/>}
                                  label={item.label} value={item.id}/>
            </div>)
    });


    return (
        <div>
            <div className={`${styles.body} rounded fw-bold`}>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    defaultValue={state.value}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center"
                    }}>
                    {
                        items
                    }
                </RadioGroup>
            </div>
        </div>
    )
})
export default ListRadioOptions