import React from "react";
import './VideoSection.css'
import {Props} from "./interface";


const VideoSection = (props: Props) => {

    return (
        <div className={`video-section`}>
        </div>
    )
}
export default VideoSection