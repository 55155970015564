import React, {useContext} from "react";
import {Props} from "./interface";
import {useTranslation} from "react-i18next";
import styles from './ListCellOptions.module.css'
import {Item} from "../../../../hooks/use-search/interfaces";
import DynamicObject from "../../../../models/dynamic-object";
import useSearch from "../../../../hooks/use-search/use-search";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import languageContext from "../../../../storage/LanguageContext";
import {RefDataType} from "../../Tools/ListCellOptions/interfaces";
import {getImgPath, getSizes} from "../../../../helpers/functions";

const ListCellOptions = React.forwardRef<RefDataType, Props>((props, ref) => {

    const {config, saveHttp} = props

    /************************************
     *            CONTEXT
     * *********************************/
    const langCtx = useContext(languageContext);

    /************************************
     *           TRANSLATION
     * *********************************/
    const {t} = useTranslation();

    /*-------------------------------------
     *              HOOKS
     * ----------------------------------*/

    /************************************
     *        CUSTOME SEARCH HOOK
     * *********************************/
    const {filteredResults, Search} = useSearch({
        items: config.values,
        search: {
            placeholder: config?.search?.placeholder?.locale
        }
    })

    /*-----------------------------------*/

    /************************************
     *       INLINE LABEL STYLE
     * *********************************/
    const labelStyle = {
        fontWeight: config.cell.label.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.cell.label.style?.size),
        color: config.cell.label.color,
    } as React.CSSProperties

    /*-------------------------------------
     *             FUNCTIONS
     * ----------------------------------*/

    /************************************
     *        ACTION HANDLER
     * *********************************/
    const addItemHandler = (item: Item) => {
        const httpConfig: DynamicObject = {
            config: {
                data: {
                    [`${config.identifier}`]: item.id,
                }
            }
        }
        saveHttp?.request(httpConfig)
    }
   /*-----------------------------------*/

    return (
        <div className={styles.container}>
            {config?.search &&
                <Search></Search>
            }
            <div className={styles.items_container}>
                {
                    filteredResults.length < 1 && (
                        <div className={`row ${styles.no_found_data}`}>
                            <h3>
                                {t('noResults')}
                            </h3>
                        </div>
                    )
                }
                {
                    filteredResults.map(item => {
                            if (item.id) {
                                return (
                                    <div key={item.id} className={`row ${styles.item}`}
                                         onClick={(e: React.MouseEvent<HTMLElement>) => addItemHandler(item)}>
                                        <div className={`col-md-9 col-10 ${styles.image_text}`}>
                                            {

                                                item.icon && (
                                                    <div className={styles.image_container}>
                                                        <img className={styles.image}
                                                             style={{width: config.identifier.includes("categoriesMain") ? "50px" : "30px"}}
                                                             src={getImgPath(item.icon)}/>
                                                    </div>
                                                )
                                            }


                                            <div className={styles.text}>
                                                <h1 style={labelStyle}>{item.label}</h1>
                                            </div>
                                        </div>
                                        <div className={`col-md-3 col-2 ${styles.icon}`}>
                                            {(langCtx.language == 'en') ?
                                                <IoIosArrowForward style={{height: "21.2px", width: "22.85px"}}/> :
                                                <IoIosArrowBack style={{
                                                    height: "21.2px",
                                                    width: "22.85px"
                                                }}/>}                                        </div>
                                    </div>
                                )
                            }
                            return (
                                <h3 style={{textAlign: "center"}}>{item.label}</h3>
                            )
                        }
                    )
                }
            </div>
        </div>
    )
})
export default ListCellOptions