import React, {useContext} from "react";
import {Props, RefDataType} from './interfaces';
import styles from './Section.module.css'
import {getImgPath, getSizes} from "../../../../helpers/functions";
import UIContext from "../../../../storage/UIContext";

const Section = React.forwardRef<RefDataType, Props>((props, ref) => {

    const {config} = props
    const uiCtx=useContext(UIContext)

    {/*TITLE STYLING */
    }

    const containerStyle = {
        "--background-color": props.config?.background?.color,
        "--border-color": props.config?.border?.color
    } as React.CSSProperties

    const titleStyle = {
        fontWeight: config.title?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.title?.style?.size),
        color: config.title?.color,
    } as React.CSSProperties

    const subTitleStyle = {
        fontWeight: config.subTitle?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.subTitle?.style?.size),
        color: config.subTitle?.color,
    } as React.CSSProperties

    return (
        <div className={styles.container} style={containerStyle}>
            {config?.title && <h1 style={titleStyle}>{config.title?.locale}</h1>}
            {config?.subTitle && <div style={{display: "flex", gap: "5px"}}>
                <div className={`${styles.flexContainer}`}>
                    <div>{config?.subTitle.icon &&
                        <img style={{width:"20px"}} src={getImgPath(config?.subTitle.icon)}/>}</div>
                    <div className={`ms-1`}><span style={subTitleStyle}>{config?.subTitle?.locale}</span></div>
                </div>
            </div>}
            {props.children}
        </div>
    )
})
export default Section