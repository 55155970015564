import React, {useContext} from "react";
import DynamicObject from "../../../models/dynamic-object";
import {UseHttpResponse} from "../../../hooks/use-http/interfaces";
import {Button as MuiButton} from "@mui/material";
import {getSizes} from "../../../helpers/functions";
import UIContext from "../../../storage/UIContext";
import styles from "./NextButton.module.css"
import CircularProgress from "@mui/material/CircularProgress";
interface NextButtonProps {
    nextApi?: UseHttpResponse<DynamicObject>;
    style?: {
        bold?: boolean,
        size?: string,
        color?: string,
        backgroundColor?: string
        borderRadius?: string
        marginTop?: string
        width?:string
    },
    children?: React.ReactNode;
    className?: string
    onClick?: Function
    isSticky:boolean
}


const NextButton = (props: NextButtonProps): JSX.Element => {
    const {nextApi, style, children} = props

    const uiCtx=useContext(UIContext)

    const submitStyle = {
        fontWeight: style?.bold ? "bold" : "normal",
        fontSize: getSizes(style?.size || "m"),
        width:style?.width || !props.isSticky?"100%": "500px",
        borderRadius: style?.borderRadius ? style.borderRadius : "8px",
        color: style?.color,
        backgroundColor: style?.backgroundColor,
         margin:props.isSticky?"5px":"0px" ,
        padding:"10px"

    } as React.CSSProperties


    const nextHandler = () => {
        if (props.onClick) {
            return props.onClick(nextApi)
        }

        const httpConfig: DynamicObject = {}
        nextApi?.request({})
    }

    return (
        <div className={`${props.isSticky?styles.button_container:''}`}  style={{width:"100%"}}>

            <MuiButton disabled={uiCtx.btnLoading&&true} id={'next-btn'} variant='contained' style={submitStyle}
                       className={`d-block`}
                       onClick={(e: React.MouseEvent<HTMLElement>) => nextHandler()}>{children}

            </MuiButton>

        </div>

    )
}

export default NextButton