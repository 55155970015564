import {Props,RefDataType} from './interfaces';
import {useHttp} from "../../../../hooks";
import MenuItem from "@mui/material/MenuItem";
import styles from './SelectField.module.css'
import WidgetApi from "../../../../api/widget.api";
import React, {useContext, useState} from "react";
import {getSizes} from "../../../../helpers/functions";
import PostContext from "../../../../storage/PostContext";
import {Args} from "../../../../hooks/use-http/interfaces";
import {FormControl, InputLabel, Select} from "@mui/material";
import DynamicObject from "../../../../models/dynamic-object";

const SelectField = React.forwardRef<RefDataType, Props>((props, ref) => {

    const {config} = props
    const postCtx = useContext(PostContext)

    const itemsList = config.values.map((item) =>
        <MenuItem value={item.id}>{item.label}</MenuItem>
    );
    {/*THIS IS FOR ITEM LIST */}
    const [items, setItems] = useState(itemsList)

    {/*THIS IS FOR LOADER */}
    const [isOpen, setIsOpen] = useState<boolean>(false)

    {/*CONFIGURATION WEDGET API */}
    const configWidgetApi = {
        callback: WidgetApi,
        initialData: {},
        withLoader: false
    }
    {/*PREPARE REQUEST */}
    const widgetApi = useHttp<DynamicObject>(configWidgetApi)
    const request = widgetApi.request

    {/*REQUEST API TO GET VALUES */}
    widgetApi.request = (args?: Args) => {
        return request({
            ...args,
            config: {
                ...args?.config,
                language: document.cookie.replace(/(?:(?:^|.*;\s*)lang\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
                params: {
                    id: config.identifier,
                    type: 'add-post',
                    workflowId: postCtx.data.workflow_id,
                    draftId: postCtx.data.draft_id,
                    stepId: postCtx.data.step?.identifier
                }
            }
        })
    }

    const onOpenHandler = () => {
        widgetApi?.request()
        setIsOpen(true)
    }

    return (
        <>
            <div className={`${styles.container}`}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" className={`${styles.text_overflow}`} style={{
                        color: config.title.color,
                        fontSize: getSizes(config.title.style?.size),
                        fontWeight: config.title.style?.bold ? "bold" : "normal"
                    }}>{config.title.locale}</InputLabel>
                    <Select
                        disabled={!config.editable}
                        className={`${styles.select_field}`}
                        labelId="demo-simple-select-label"
                        onOpen={onOpenHandler}
                        value={config?.values[0]?.id}>
                        {items}
                    </Select>
                </FormControl>
            </div>
        </>
    )
})
export default SelectField