import React, {useContext} from "react";
import {FormHelperText} from "@mui/material";
import DynamicObject from "../../../models/dynamic-object";
import LanguageContext from "../../../storage/LanguageContext";


interface Props {
    validation: DynamicObject
}

const ValidationError = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {validation} = props
    const langCtx = useContext(LanguageContext)
    const alignCenter = (langCtx.language == 'en') ? 'left' : 'right'
    return (
        <div ref={ref}>
            {validation.error && <FormHelperText style={{
                color: "red",
                fontSize: "15px",
                paddingTop: "2px",
                textAlign: alignCenter
            }}>
                {`*  ${validation.error}`}
            </FormHelperText>

            }
        </div>
    )
})

export default ValidationError