import styles from './ComponentLoader.module.css'
import {CircularProgress} from "@mui/material";


const ComponentLoader = () => {
    return (
        <div className={styles.container}>
            <CircularProgress />
        </div>
    )

}

export default ComponentLoader