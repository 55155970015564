import './PreviewStep.css'
import React, {useContext, useEffect, useState} from "react";
import stepModule from '../Step.module.css'
import MapperPreview from "../../../MapperPreview";
import PostContext from "../../../../storage/PostContext";
import DynamicObject from "../../../../models/dynamic-object";
import {PreviewStep as PreviewStepModel} from '../../../../models'
import {UseHttpResponse} from "../../../../hooks/use-http/interfaces";
import RefType from "../../../../models/ref-type";
import Step from "../Step";
import {useTranslation} from "react-i18next";
import { IoIosWarning } from "react-icons/io";
import CircularProgress from "@mui/material/CircularProgress";
import UIContext from "../../../../storage/UIContext";
import {ComponentLoader} from "../../../UI";


interface Props {
    children?: React.ReactNode;
    nextApi?: UseHttpResponse<DynamicObject>;
    config: PreviewStepModel
}

interface RefDataType extends RefType<DynamicObject> {
}

const PreviewStep = React.forwardRef<RefDataType, Props>((props, ref): JSX.Element => {


    const {config} = props
    const [configData, setConfigData] = useState<DynamicObject>(config)
    const {t} = useTranslation();
    const postCtx = useContext(PostContext)
    const uiCtx =useContext(UIContext)

    useEffect(function () {
        postCtx.updatePreviewStep(configData)
        // scoreHttp?.request(httpConfig)
    }, [])


    {/*CONTENT*/
    }
    const Content = () => {
        return <>
            {uiCtx.isLoading && <ComponentLoader/>}
            {(configData) &&
            <MapperPreview config={configData} setConfigData={setConfigData}/>}
        </>
    }
    return (
        <Step>
            {uiCtx.isLoading && <CircularProgress/>}
            {/*{config.title && <div style={{padding: "5px 10px"}}>*/}
            {/*    <p style={{*/}
            {/*        fontWeight: config.title.style?.bold ? "bold" : "normal",*/}
            {/*        fontSize: getSizes(config.title.style?.size),*/}
            {/*        color: config.title.color,*/}
            {/*        margin:"0"*/}
            {/*    }}>{config.title.locale}</p>*/}
            {/*</div>}*/}

            <div className={`${stepModule.content} preview_step`}>
                {postCtx.data.remaining_edit_counter && <div style={{
                    padding: "5px",
                    display: "flex",
                    gap: "5px",
                    marginTop: "10px",
                    backgroundColor: "#f9f9f9",
                    borderRadius: "10px"
                }}>
                    <IoIosWarning style={{height: "20px", width: "20px"}}></IoIosWarning>
                    <div style={{
                        fontWeight: "bold",
                        fontSize: "15px"
                    }}>  {`${t('NumberOfPostEditLeft')} ${postCtx.data.remaining_edit_counter}`}
                    </div>
                </div>}
                <Content/>
            </div>
        </Step>
    )
})

export default PreviewStep