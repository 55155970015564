import React, {useContext, useState} from "react";
import {Props} from "./interface";
import {FaTimesCircle} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import styles from './ListCheckboxOptions.module.css'
import PostContext from "../../../../storage/PostContext";
import DynamicObject from "../../../../models/dynamic-object";
import useSearch from "../../../../hooks/use-search/use-search";
import {getItemsAsString, getSizes} from "../../../../helpers/functions";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import {Button as MuiButton, Checkbox, FormControlLabel, FormGroup, Typography} from "@mui/material";


const ListCheckboxOptions = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    const {config} = props

    /*-------------------------------------
     *              HOOKS
     * ----------------------------------*/

    /************************************
     *   SET & GET VALIDATION AND STATES
     * *********************************/
    const {state, validation} = useDataPayload<string>({
        ref,
        config: props.config
    })

    /************************************
     *       CUSTOME SEARCH HOOK
     * *********************************/
    const {filteredResults, Search} = useSearch({
        items: config.values,
        search: {
            placeholder: config?.search?.placeholder?.locale
        }
    })

    /*---------------------------------------------------------------*/

    /************************************
     *          TRANSLATION
     * *********************************/
    const {t} = useTranslation();

    /************************************
     *            CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)

    /*-------------------------------------
     *            FUNCTIONS
     * ----------------------------------*/

    /************************************
     *         GET ITEM INFO
     * *********************************/
    const getItemInfo = (id: number | string) => {
        return config?.values?.find(item => item.id == id)
    }

    /************************************
     *        PREPARE DATE
     * *********************************/
    const prepareData = () => {
        const items: DynamicObject = {}
        const dataItems = postCtx.data.form[config.identifier] ? postCtx.data.form[config.identifier]?.split(',') ?? [] : []
        if (dataItems.length > 0) {
            dataItems.map((value: any) => {
                const item: any = getItemInfo(value)
                items[`${item.id}` as string] = item.label
            })
        }
        return items
    }

    /*-------------------------------------
    *            STATE
    * ----------------------------------*/
    const [checkedValues, setCheckedValues] = useState<DynamicObject>(prepareData)

    /*---------------------------------------------------------------*/

    /************************************
     *       ACTIONS HANDLER
     * *********************************/
    const addItemHandler = (item: DynamicObject) => {
        setCheckedValues(old => {
            return {
                ...old,
                [`${item.id}`]: item.label
            }
        })
        validation.clear()
    }
    /************************************
     *     UNCHECK ITEMS HANDLER
     * *********************************/
    const removeItemHandler = (item: DynamicObject) => {
        setCheckedValues(old => {
            const state = {...old}

            delete state[item.id]

            return state
        })
    }

    /************************************
     *     ON CHANGE HANDLER
     * *********************************/
    const changeItem = (e: React.ChangeEvent<HTMLInputElement>, item: DynamicObject) => {
        if (!e.target.checked) {
            removeItemHandler(item)
            return
        }
        addItemHandler(item)
    }

    /***********************************
     *    GET CHECKED ITEMS & UPDATE
     *         ON CONTEXT
     * *********************************/
    const pushItems = () => {
        var result = Object.keys(checkedValues).map(function (key) {
            return {id: Number(key), label: checkedValues[key]};
        });

        postCtx.addToForm(config.identifier, getItemsAsString(Object.keys({...checkedValues})))

        const previewConfig: DynamicObject = postCtx.previewStep
        const lastPreview = previewConfig.childs.filter((child: any) => {
            if (child.identifier === config.identifier) {
                delete child.values
                child.values = [...result]
                return {
                    ...child,
                }
            } else {
                return child
            }
        })

        const finalConfig = {
            ...previewConfig,
            childs: lastPreview
        }

        props.setConfigData(finalConfig);
    }

    /*---------------------------------------------------------------*/

    return (
        <div>
            {config?.search &&
                <Search></Search>
            }
            {props.config.tags && <div className={` row ${styles.checked_container}`}>
                {
                    config.values.map((item) => {
                        if (!checkedValues[item.id])
                            return

                        return (<div className={`${styles.checked_item_box}`}>
                            <div className={styles.checked_item_box_container}>
                                <span>{item.label}</span>
                                <FaTimesCircle className={`cursor__pointer`}
                                               onClick={(e: React.MouseEvent) => removeItemHandler(item)}/>
                            </div>
                        </div>)
                    })
                }
            </div>
            }
            <div className={`row mt-1 ${styles.scrollDiv} `}>

                <div className={`${styles.container}`} style={{overflow: "hidden"}}>
                    <div className={`rounded fw-bold `}>
                        {
                            filteredResults.length < 1 && (
                                <div className={`row ${styles.no_found_data}`}>
                                    <h3>
                                        {t('noResults')}
                                    </h3>
                                </div>
                            )
                        }
                        {
                            filteredResults.map((item, i) => {
                                return <div className={`${styles.item}`}>
                                    <FormGroup>
                                        <FormControlLabel
                                            sx={{padding: "10px"}}
                                            control={
                                                <Checkbox
                                                    onChange={(e) => changeItem(e, item)}

                                                    checked={!!checkedValues[item.id]}
                                                />
                                            }
                                            label={
                                                <Typography sx={{
                                                    marginTop: "6px",
                                                    fontWeight: config.cell.label.style.bold ? 'bold' : 'normal',
                                                    fontSize: getSizes(config.cell.label.style.size),
                                                    color: config.cell.label.color
                                                }}>
                                                    {item.label}
                                                </Typography>
                                            }
                                            value={item.id}
                                        />
                                    </FormGroup>

                                </div>
                            })
                        }
                    </div>

                </div>
                <ValidationError validation={validation}/>
              <div className={`${styles.button_container}`}>
                  <MuiButton style={{height: "50px",width:"100%",backgroundColor:"#1976d2",fontWeight:"bold",borderRadius:"8px"}} variant='contained'
                             className={`d-block`}
                             onClick={(e) => pushItems()}
                  >Save</MuiButton>
              </div>

            </div>
        </div>
    )
})

export default ListCheckboxOptions