import React, {useEffect, useState} from "react";
import DynamicObject from "../models/dynamic-object";
import {StateDataType as Media} from "../components/UI/MediaUpload/MediaUpload.interfaces";

export interface MediaResult {
    [key: string]: Media
}

interface BaseDataType {
    workflow_id: string,
    draft_id: string,
    remaining_edit_counter?:string|number
    start_time: number,
    step?: DynamicObject,
    previous?: DynamicObject,
    flow_type?: string,
    isFinished?: boolean,
    isEdit?: boolean,
    post_id?: number
}

export interface PostDataType extends BaseDataType {
    form: DynamicObject,
    currentStepData: DynamicObject,
    media: MediaResult
}


const DEFAULT_DATA: PostDataType = {
    form: {},
    currentStepData: {},
    workflow_id: "",
    draft_id: "",
    remaining_edit_counter:undefined,
    start_time: 0,
    step: undefined,
    flow_type: 'post',
    media: {},
    isFinished: false,
    isEdit:false,
    post_id: 0
}

const PostContext = React.createContext({
        data: DEFAULT_DATA,
        previewStep: {},

        addToForm: (key: string, value: any) => {
        },
        updateForm: (key: string, value: any) => {
        },
        updateData: (data: DynamicObject): void => {
        },
        updatePreviewStep: (data: DynamicObject): void => {
        },
        updateStep: (data: BaseDataType): void => {
        }
    }
)

export const PostContextProvider = (props: { children: React.ReactNode }) => {

    //states
    const [data, setData] = useState<PostDataType>(DEFAULT_DATA);
    const [previewStep, setPreviewStep] = useState<DynamicObject>({});
console.log(data.step, 'step')
    useEffect(() => {

    }, [data])
    const addToForm = (key: string, value: any) => {
        setData((old: PostDataType) => {
            const newData = {
                ...old
            }
            newData.form[key] = value
            newData.currentStepData[key] = true
            return newData
        })
    }

    const updateForm = (key: string, value: any) => {
        setData((old: PostDataType) => {
            return {
                ...old,
                [`${key}`]: value
            }
        })
    }

    const updateData = (newData: DynamicObject) => {
        setData((old: PostDataType) => {
            return {
                ...old,
                ...newData
            }
        })
    }

    const updateStep = (newData: BaseDataType) => {
        setData((old: PostDataType) => {
            return {
                ...old,
                ...newData,
                form: old.form,
                currentStepData: {},
            }
        })
    }

    const updatePreviewStep = (data: DynamicObject) => {
        setPreviewStep(data)
    }




    return (
        <PostContext.Provider
            value={{
                data,
                previewStep,
                updateData,
                updatePreviewStep,
                updateStep,
                addToForm,
                updateForm
            }}>
            {props.children}
        </PostContext.Provider>
    );
}

export default PostContext