import React, {useContext, useEffect, useReducer} from "react";
import {
    DraftDataDataType,
    FileDataType,
    MediaAction,
    MediaActionKind,
    MediaList,
    StateDataType
} from "./MediaUpload.interfaces";
import MediaUpload from "./MediaUpload";
import DynamicObject from "../../../models/dynamic-object";
import uploadMediaApiDraft from "../../../api/upload-media-draft.api";
import uploadMediaApi from "../../../api/upload-media.api";
import {generateFileHash} from "../../../helpers/media.functions";
import deleteMediaApi from "../../../api/delete-media.api";
import PostContext from "../../../storage/PostContext";
import useValidationNew from "../../../hooks/use-validation/use-validation-new";
import {t} from "i18next";
import MediasCacher from "../../../models/medias-cacher";
import {useNavigate} from "react-router-dom";
import {parentCallBack} from "../../../helpers/functions";

interface MediaProps {
    identifier: string,
    maxLength?: number,
    onChangeMedia?: (state: StateDataType) => void
}


const DEFAULT_MEDIA_NUMBER = 9
const DEFAULT_MAX_MEDIA_NUMBER = 30
const DEFAULT_STATE = {
    medias: {},
    main_media: ""
}


const Media = (props: MediaProps): JSX.Element => {
    const {maxLength = DEFAULT_MAX_MEDIA_NUMBER, identifier} = props


    const reducer = (oldState: StateDataType, action: MediaAction): StateDataType => {
        const {type, payload} = action


        switch (type) {
            case MediaActionKind.ADD_MEDIA:
                const data: MediaList = {}
                for (const media of payload.medias) {
                    data[media.hash] = {
                        url: media.url,
                        uri: media.url,
                        progress: 0,
                        isLoaded: false
                    }
                }

                console.log({
                    ...oldState.medias,
                    // ...data,
                }, 'add media')
                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        ...data,
                    },
                    main_media: !!payload?.isMain ? payload.medias[0].hash : oldState.main_media
                }
            case MediaActionKind.UPDATE_MEDIA:

                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        [`${payload.fileHash}`]: {
                            ...oldState.medias[`${payload.fileHash}`],
                            ...payload.data
                        }
                    }
                }
            case MediaActionKind.UPDATE_MEDIAS:
                const updatedMedias: MediaList = {}
                for (const hash in payload.medias) {
                    updatedMedias[hash] = {
                        ...oldState.medias[hash],
                        ...payload.medias[hash]
                    }
                }
                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        ...updatedMedias
                    }
                }
            case MediaActionKind.CHANGE_MEDIA:

                delete oldState.medias[payload.oldFileHash]
                if (oldState.main_media === payload.oldFileHash)
                    oldState.main_media = payload.new.fileHash

                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        [`${payload.new.fileHash}`]: {
                            url: payload.new.fileUrl,
                            isMain: !!payload?.new.isMain,
                            progress: 0,
                            isLoaded: false,
                        },
                    }
                }
            case MediaActionKind.DELETE_MEDIA:
                const newState = {...oldState}

                delete newState.medias[payload.fileHash]

                if (newState.main_media === payload.fileHash)
                    newState.main_media = undefined

                const medias = Object.keys(newState.medias)

                if (!newState.main_media && medias.length > 0)
                    newState.main_media = medias[medias.length - 1]

                if (props.onChangeMedia)
                    props.onChangeMedia(newState)

                return {
                    ...newState,
                }

            case MediaActionKind.UPDATE_PROGRESS:
                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        [`${payload.fileHash}`]: {
                            ...oldState.medias[`${payload.fileHash}`],
                            progress: payload.progress
                        }
                    },
                }
            case MediaActionKind.UPDATE_IS_LOADED:
                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        [`${payload.fileHash}`]: {
                            ...oldState.medias[`${payload.fileHash}`],
                            isLoaded: payload.isLoaded,
                        }
                    },
                }
            case MediaActionKind.CHANGE_MAIN_MEDIA:
                const allMedias = {...oldState.medias}
                delete allMedias[oldState.main_media as string]

                const result = {
                    ...oldState,
                    medias: {
                        ...allMedias,
                        [`${oldState.main_media}`]: {...oldState.medias[oldState.main_media as string]},
                    },
                    main_media: payload.main_media
                }

                if (props.onChangeMedia)
                    props.onChangeMedia(result)


                return result
            case MediaActionKind.DRAFTING:
                let newData: StateDataType = {
                    ...oldState,
                }

                if (payload.medias) {
                    for (const hash in payload.medias) {
                        newData.medias[hash] = {
                            ...oldState.medias[hash],
                            ...payload.medias[hash],
                            isLoaded: true,
                            progress: 100
                        }
                    }
                    delete payload.medias
                }

                newData = {
                    ...newData,
                    ...payload
                }


                if (props.onChangeMedia)
                    props.onChangeMedia(newData)

                return {
                    ...newData,
                }
            case MediaActionKind.FORCE_CHANGE:
                return {
                    ...payload
                }
            default:
                return {
                    ...oldState
                }
        }
    }

    const postCtx = useContext(PostContext)
    const [state, dispatch] = useReducer(reducer, MediasCacher.getInstance().get(identifier) || DEFAULT_STATE)
    const {setValidationError, clearValidationError, error} = useValidationNew()


    const checkIsValid = (file: File) => {

        if (!file.type.match("image.*")) {
            setValidationError(t("validation__file-isn't-not-image"))
            return false
        }
        clearValidationError()
        return true
    }

    const getHashById = (id: string): string => {
        for (const hash in state.medias) {
            const media = state.medias[hash]
            if (media.id == id) {
                return hash
            }
        }
        return ''
    }

    function saveToDraft(draftData: DraftDataDataType) {
        const {uris, mainUri} = draftData

        const data: DynamicObject = {
            [`${identifier}`]: {
                uri: uris,
            },
            identifier: identifier,
            draftId: postCtx.data.draft_id,
            workflowId: postCtx.data.workflow_id,
        }

        if (mainUri)
            data.postMedia["main_media_uri"] = mainUri

        return uploadMediaApiDraft({
            draftId: postCtx.data.draft_id,
            data,
            bucket: 8
        })
    }

    const uploadFileRequest = (mediaFiles: FileDataType[], mainHash: string = "") => {
        const promises: Promise<any>[] = []

        for (const mediaFile of mediaFiles) {


            const promise = () => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(mediaFile.file)


                reader.onload = (e) =>
                    uploadMediaApi({
                        onUploadProgress: (progress: number) => {
                            dispatch({
                                type: MediaActionKind.UPDATE_PROGRESS,
                                payload: {
                                    fileHash: mediaFile.hash,
                                    progress
                                }
                            })
                        },
                        onDownloadProgress: (progress: number) => {
                            dispatch({
                                type: MediaActionKind.UPDATE_PROGRESS,
                                payload: {
                                    fileHash: mediaFile.hash,
                                    progress
                                }
                            })


                        },
                        data: e?.target?.result,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    })
                        .then(response => {


                            // MediasCacher.getInstance().update(identifier, {
                            //     ...MediasCacher.getInstance().get(identifier),
                            //     medias: {
                            //         ...MediasCacher.getInstance().get(identifier)?.medias,
                            //         [`${mediaFile.hash}`]: {
                            //             ...MediasCacher.getInstance().get(identifier)?.medias[mediaFile.hash],
                            //             isLoaded: true,
                            //             progress: 100
                            //         }
                            //     }
                            // })


                            const data: DraftDataDataType = {
                                uris: [response.data.uri],
                            }

                            if (mainHash === mediaFile.hash)
                                data.mainUri = response.data.uri

                            return saveToDraft(data)

                        }).then((responseDraft) => {
                        const medias: DynamicObject = {}
                        const mediaCaches: DynamicObject = {}

                        for (const uri in responseDraft.data.result.data[identifier]) {
                            const postMedia = responseDraft.data.result.data.postMedia[uri]
                            const hash = mediaFile.hash
                            medias[hash] = {
                                id: postMedia.id,
                                uri: uri,
                                isLoaded: true,
                                progress: 100
                            }

                            mediaCaches[hash] = {
                                ...MediasCacher.getInstance().get(identifier)?.medias[hash],
                                ...medias[hash]
                            }
                        }

                        dispatch({
                            type: MediaActionKind.DRAFTING,
                            payload: {
                                medias
                            }
                        })

                        console.log(MediasCacher.getInstance().get(identifier), 'old')
                        MediasCacher.getInstance().update(identifier, {
                            ...MediasCacher.getInstance().get(identifier),
                            medias: {
                                ...MediasCacher.getInstance().get(identifier)?.medias,
                                ...mediaCaches
                            }
                        })


                    }).catch((err) => console.log("error", err))

            })
            promises.push(promise as any)
        }


        return promises;

    }

    const addMedia = (files: FileList | null, mainImage: boolean): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (!files?.length)
                return

            const mediaNumber = Object.values(state.medias).length + files.length

            if (mediaNumber > maxLength) {
                setValidationError(t("validation__more_than_max_length_images").toString().format(maxLength))
                return;
            }


            const mediaFiles: FileDataType[] = []
            const mediaCaches: DynamicObject = {}

            for (let index = 0; index < files.length; index++) {
                const file = files[index]
                const hash = generateFileHash(file)

                if (state.medias[hash])
                    continue

                if (!checkIsValid(file))
                    return;

                const url = URL.createObjectURL(file)

                mediaCaches[hash] = {
                    url,
                    uri: url,
                    progress: 0,
                    isLoaded: false
                }

                mediaFiles.push({
                    file,
                    hash,
                    url: url
                })
            }

            const mainImageHash = mainImage ? mediaFiles[0].hash : ''


            MediasCacher.getInstance().update(identifier, {
                ...MediasCacher.getInstance().get(identifier),
                medias: {
                    ...mediaCaches,
                    ...MediasCacher.getInstance().get(identifier)?.medias,
                },
                main_media: mainImageHash || MediasCacher.getInstance().get(identifier)?.main_media
            })

            dispatch({
                type: MediaActionKind.ADD_MEDIA,
                payload: {
                    medias: mediaFiles,
                    isMain: !!mainImageHash
                }
            })


            const promises = uploadFileRequest(mediaFiles, mainImageHash)

            Promise.all(promises.map(async promise => (await promise)()))
                .then(result => {
                })
        });

    }

    function uploadMedia(file: File, fileHash: string) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file)
        reader.onload = (e) => uploadMediaApi({
            onUploadProgress: (progress: number) => {
                dispatch({
                    type: MediaActionKind.UPDATE_PROGRESS,
                    payload: {
                        fileHash,
                        progress
                    }
                })
            },
            onDownloadProgress: (progress: number) => {
                dispatch({
                    type: MediaActionKind.UPDATE_PROGRESS,
                    payload: {
                        fileHash,
                        progress
                    }
                })
            },
            data: e.target?.result,
            headers: {
                "Content-Type": "multipart/form-data",
            }

        }).then(response => {

            saveToDraft({uris: [response.data.uri]})
                .then(responseDraft => {
                    dispatch({
                        type: MediaActionKind.DRAFTING,
                        payload: {
                            medias: {
                                [`${fileHash}`]: {
                                    id: responseDraft.data.result.data[identifier][response.data.uri].id,
                                }
                            }
                        }
                    })

                    MediasCacher.getInstance().update(identifier, {
                        ...MediasCacher.getInstance().get(identifier),
                        medias: {
                            ...MediasCacher.getInstance().get(identifier)?.medias,
                            [`${fileHash}`] : {
                                ...MediasCacher.getInstance().get(identifier)?.medias[fileHash],
                                progress: 100,
                                isLoaded: true,
                                uri: response.data.uri,
                                id: responseDraft.data.result.data[identifier][response.data.uri].id,
                            }
                        }
                    })
                });
        })
    }

    const changeMainImage = (fileHash: string) => {
        const data: DraftDataDataType = {
            uris: [state.medias[fileHash].uri as string],
            mainUri: state.medias[fileHash].uri
        }
        saveToDraft(data)
            .then(response => {
                dispatch({
                    type: MediaActionKind.CHANGE_MAIN_MEDIA,
                    payload: {
                        main_media: fileHash
                    }
                })

                MediasCacher.getInstance().update(identifier, {
                    ...MediasCacher.getInstance().get(identifier),
                    main_media: fileHash
                })

            })
    }

    const changeMedia = (files: FileList | null, oldImageHash: string) => {

        if (!files?.length)
            return

        const file = files[0]
        const fileUrl = URL.createObjectURL(file)
        const fileHash = generateFileHash(file)

        if (state.medias[fileHash]) {
            return;
        }

        if (!checkIsValid(file))
            return;


        dispatch({
            type: MediaActionKind.CHANGE_MEDIA,
            payload: {
                oldFileHash: oldImageHash,
                new: {
                    fileHash,
                    fileUrl
                }
            }
        })

        const mediaObj = {
            ...MediasCacher.getInstance().get(identifier)
        }

        delete mediaObj.medias[oldImageHash]

        if (mediaObj.main_media === oldImageHash)
            mediaObj.main_media = fileHash

        mediaObj.medias[fileHash] = {
            url: fileUrl,
            uri: fileUrl,
            progress: 0,
            isLoaded: false
        }

        MediasCacher.getInstance().update(identifier, {
            ...mediaObj
        })


        deleteMedia(oldImageHash).then(() => {
            uploadMedia(file, fileHash);
        })
    }

    const deleteMedia = (mediaHash: string) => {
        const mediaObject = state.medias[mediaHash]
        return deleteMediaApi({
            mediaId: mediaObject.id
        })
    }

    const onDeleteMedia = (mediaId: string) => {
        const mediaHash = getHashById(mediaId)
        if (mediaHash)
            deleteMedia(mediaHash)
                .then(() => {
                    dispatch({
                        type: MediaActionKind.DELETE_MEDIA,
                        payload: {
                            fileHash: mediaHash
                        }
                    })

                    const mediaObj = {
                        ...MediasCacher.getInstance().get(identifier)
                    }

                    const mediasKeys = Object.keys(mediaObj.medias)

                    if (mediaObj.main_media === mediaHash && mediasKeys.length > 1)
                        mediaObj.main_media = mediasKeys[mediasKeys.length - 1]

                    delete mediaObj.medias[mediaHash]

                    MediasCacher.getInstance().update(identifier, {
                        ...mediaObj
                    })

                })
    }

    useEffect(() => {
        const eventHandler = (e: CustomEvent) => {
            const data = e.detail
            dispatch({
                type: MediaActionKind.FORCE_CHANGE,
                payload: data.medias[identifier]
            })
        }

        parentCallBack('with_confirm_dialog')

        window.addEventListener("update-media", eventHandler)
        return () => {
            window.removeEventListener('update-media', eventHandler)
        }
    }, [])

    return (
        <MediaUpload
            state={state}
            addMedia={addMedia}
            changeMainImage={changeMainImage}
            changeMedia={changeMedia}
            onDeleteMedia={onDeleteMedia}
            defaultMediaNumber={DEFAULT_MEDIA_NUMBER}
            maxLength={DEFAULT_MAX_MEDIA_NUMBER}
            error={error}
        />
    )
}

export default Media