import React, {FunctionComponent, FunctionComponentElement, Ref, useContext, useEffect, useState} from "react";
import * as flow from './Flow/PreviewTools/index'
import DynamicObject from "../models/dynamic-object";
import NextButton from "./Flow/NextButton/NextButton";
import PublishApi from "../api/puplish.api";
import {useHttp} from "../hooks";
import {AxiosError, AxiosResponse} from "axios";
import {Args, UseHttpResponse} from "../hooks/use-http/interfaces";
import PostContext from "../storage/PostContext";
import LanguageContext from "../storage/LanguageContext";
import UIContext from "../storage/UIContext";
import {getUrlBasedOnCountry, parentCallBack, removeMatchingKeys} from "../helpers/functions";
import CircularProgress from "@mui/material/CircularProgress";
import ActiveRequests from "../models/active-requests";
import PendingUploads from "./PendingUploads/PendingUploads";
import {getDataStorage} from "../helpers/storage.helper";
import SectionCacher from "../models/section-cacher";
import {REFRESH_PAGE} from "../constants/Status";

interface Config {
    [key: string]: any
}

interface Props {
    config: Config,
    setConfigData: React.Dispatch<React.SetStateAction<any>>;
}


interface RefType {
    getState: () => DynamicObject
}


interface ValidatePublish {
    isValid: boolean,
    invalidElement?: {
        component: FunctionComponent,
        props: DynamicObject
    }
}

const DEFAULT_VALIDATE_PUBLISH: ValidatePublish = {
    isValid: true
}

const MapperPreview = (props: Props): JSX.Element => {
    const {config} = props
    const flowRefs: DynamicObject = {}

    const postCtx = useContext(PostContext)
    const langCtx = useContext(LanguageContext)
    const [validatePublish, setValidatePublish] = useState<ValidatePublish>(DEFAULT_VALIDATE_PUBLISH)
    const storage = getDataStorage()
    const configPublishApi = {
        callback: PublishApi,
        initialData: {},
        withLoader: true
    }
    const publishApi = useHttp<DynamicObject>(configPublishApi)
    const request = publishApi.request
    const uiCtx = useContext(UIContext)


    function loadData() {
        const data: DynamicObject = {}

        for (const key in flowRefs) {
            const ref = flowRefs[key]
            if (!ref?.current?.state?.get)
                continue

            const state = ref.current.state.get()
            const keys = key.match(/\b(\w+)\b/g) || []


            if (keys.length > 1) {
                let mainKey = keys[0] || ''
                if (!data[mainKey])
                    data[mainKey] = {}

                for (let i = 1; i < keys.length; i++) {
                    let name = keys[i]
                    data[mainKey][name] = state
                }
            } else {
                if (typeof state === "object") {
                    if (!data[key])
                        data[key] = {}

                    removeMatchingKeys(Object.keys(data[key]), state)
                    data[key] = {...data[key], ...state}
                } else {
                    data[key] = state
                }
            }
        }
        return data;
    }

    useEffect(() => {
        return ()=>{
           SectionCacher.clearInstance()
        }
    }, [])

    {/*RECURSION FUNCTION TO GET CHILD'S AND SUB COMPONENT INSIDE PREVIEW STEP */
    }
    const Mapper = (config: Config) => {
        const typeName = config.type.capitalize();
        const identifier = config.identifier
        const attributes: DynamicObject = {}
        const Component = flow[typeName as keyof typeof flow]

        {/*PREPARE REF*/
        }
        flowRefs[identifier] = React.createRef<RefType>()

        {/*CHECK IF THE COMPONENT NOT EXIST*/
        }
        if (!Component) {
            throw new Error(`Component ${typeName} doesn't Exists`)
            return <></>
        }
        attributes.ref = flowRefs[identifier]
        attributes.flowRefs = flowRefs
        attributes.loadData = loadData
        return (
            <Component setConfigData={props.setConfigData} config={config as any} {...attributes}>
                <>
                    {config.childs && config.childs.map((child: Config) => {
                        return Mapper(child)
                    })}
                </>
            </Component>
        )
    }



    publishApi.request = (args?: Args) => {
        const data = args?.config?.data;

        const urlParams = new URLSearchParams(window.location.search)

        const onSuccessPublishApi = (response: AxiosResponse) => {
            if (urlParams.get('flow') == 'jobApply') {
                parentCallBack('job_applied')
                const baseUrl = getUrlBasedOnCountry(storage.country)
                const url = `${baseUrl}/${urlParams.get('lang')}/job-posters/${urlParams.get('post_id')}?applied=${true}`
                return window.top.location.href = url
            }

            parentCallBack('without_confirm_dialog')
            parentCallBack(REFRESH_PAGE)
            uiCtx.setLoading(false)
            postCtx.updateStep({
                workflow_id: response.data.workflow_id,
                draft_id: response.data.draft.id,
                step: response.data.step,
                start_time: Date.now(),
                previous: response.data.previous,
                post_id: response.data?.draft?.payload?.post_id
            })

        }

        const onError = (error: AxiosError) => {
            uiCtx.setLoading(false)
            if (error.response?.status === 422) {
                const data = error.response?.data as DynamicObject
                if (urlParams.get('flow') == 'jobApply') {
                    // alert(data?.result?.errors[0].message)

                    return
                }

                uiCtx.setErrors(data?.result?.errors as [])
            }
        }

        postCtx.updateData({
            form: {
                ...data,
            },
        })


        return request({
            ...args,
            config: {
                ...args?.config,
                language: langCtx.language,
                workflowId: postCtx.data.workflow_id,
                data: {
                    ...args?.config?.data,
                    ...data,
                    draftId: postCtx.data.draft_id,
                    time_spent_ms: Date.now() - postCtx.data.start_time,
                }
            },
            callbacks: {
                ...args?.callbacks,
                success: onSuccessPublishApi,
                error: onError,
                before: () => {
                    uiCtx.setErrors([])
                    uiCtx.setLoading(true)

                }
            }
        })
    }


    useEffect(() => {
        for (const key in uiCtx.errors) {
            const errorObject = uiCtx.errors[key] as DynamicObject

            const ref = flowRefs[errorObject.field]


            if (!ref?.current?.validation?.set)
                continue

            if (key == "0") {
                let element = document.getElementById(errorObject.field)
                element?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
            }
            ref.current.validation.set(errorObject?.message)


        }
    }, [uiCtx.errors])

    const x = config.submit ? <div><NextButton
        style={{
            bold: config.submit?.style?.bold ?? 'bold',
            size: config.submit?.style?.size ?? 'medium',
            color: config.submit?.color ?? 'white',
            backgroundColor: config.submit?.background.color ?? 'white',
        }}
        nextApi={publishApi}
        onClick={(api: UseHttpResponse<DynamicObject>) => {
            api.request = api.request.bind(this, {config: {data: loadData()}})

            if (!ActiveRequests.getInstance().uploadsCounter()) {
                return api.request()
            }

            const attributes: DynamicObject = {
                publishApi: api,
                totalRequests: ActiveRequests.getInstance().uploadsCounter()
            }

            setValidatePublish({
                isValid: false,
                invalidElement: {
                    component: PendingUploads as FunctionComponent,
                    props: {...attributes}
                }
            })

            document.getElementById("PreviousButton").style.display = "none"
        }
        }

        isSticky={config.submit?.sticky}
    >{config.submit?.locale ? config.submit?.locale : "Next"} </NextButton>
    </div> : []


    if (!validatePublish.isValid && validatePublish.invalidElement) {

        const ValidateComponent = validatePublish.invalidElement.component
        return React.createElement(ValidateComponent as FunctionComponent, {...validatePublish.invalidElement.props})
    }


    {/*
    - THIS IS CHILD'S FOR PREVIEW STEP
    - MAPPING FROM FOLDER PREVIEW TOOLS
    */
    }
    const components = config.childs.map((child: Config) => {
        return Mapper(child)
    })


    components.push(x)

    return components

}

export default MapperPreview