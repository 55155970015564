import {Props, RefDataType} from './interfaces';
import styles from './ImageLabel.module.css'
import React from "react";
import {mustache} from "../../../../helpers/functions";


const ImageLabel = React.forwardRef<RefDataType, Props>((props, ref) => {


    const imagePathData = {
        "platform": "ios",
        "size": "1x"
    };

    return (
        <div>
            <div className={`row ${styles.row}`}>
                <div className={`col ${styles.image_container}`}>
                    <img className={`${styles.image}`} src={mustache(props.config.imagePath, imagePathData)}/>
                </div>
            </div>
        </div>
    )

})

export default ImageLabel