import ArachnaApi from './requests/arachna-api'
import {AxiosInstance, AxiosProgressEvent, AxiosResponse} from "axios";
import DynamicObject from "../models/dynamic-object";

export default (config?: DynamicObject): Promise<AxiosResponse> => {
    return ArachnaApi.getInstance().request.delete(`${process.env.REACT_APP_ARACHNA_API_URL }/vertical/forms/v1/add-post/normal/drafts/media/${config?.mediaId}`, {

        params: {
            abBucket: config?.bucket
        }
    })
}