import ArachnaApi from './requests/arachna-api'
import { AxiosResponse} from "axios";
import DynamicObject from "../models/dynamic-object";
import {AddPostFilterResponse} from "../interceptors";

export default (config?: DynamicObject): Promise<AxiosResponse> => {
    const request = ArachnaApi.getInstance().request
    request.interceptors.response.use(AddPostFilterResponse().success,  AddPostFilterResponse().error)

    const requestMap = new Map([
        ['post',new Map([
            ['method',request.post],
            ['endpoint',`vertical/forms/v1/add-post/normal/${config?.data.currentStep}/${config?.data.workflowId}`]
        ])],
        ['init-cv',new Map([
            ['method',request.get],
            ['endpoint',`vertical/forms/v1/cv/normal/1`]
        ])],
        ['cv',new Map([
            ['method',request.post],
            ['endpoint',`vertical/forms/v1/cv/normal/${config?.data.currentStep}/${config?.data.workflowId}`]
        ])]
    ])

    const requestItem = requestMap.get(config?.flow_type)
    const method = requestItem?.get('method')
    const endpoint = requestItem?.get('endpoint')
    return method(`${process.env.REACT_APP_ARACHNA_API_URL}/${endpoint}`, {
        draftId: config?.data.draftId,
        time_spent_ms: config?.data.time_spent_ms,
        ...config?.data,
    })
}