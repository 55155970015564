import React, {LegacyRef, useContext, useEffect, useReducer, useRef, useState} from "react";
import styles from './MediaUpload.module.css'
import {Camera, CameraWhite} from '../../../assets/images'
import DynamicObject from "../../../models/dynamic-object";
import uploadMediaApi from "../../../api/upload-media.api";
import PostContext from "../../../storage/PostContext";
import {LinearProgress, ListItem, ListItemButton, ListItemText, Menu, MenuItem} from "@mui/material";
import uploadMediaApiDraft from "../../../api/upload-media-draft.api";
import {FaEllipsisV} from "react-icons/fa";
import deleteMediaApi from "../../../api/delete-media.api";

import {generateFileHash} from "../../../helpers/media.functions";
import {
    MediaActionKind,
    MediaAction,
    StateDataType,
    ImageComponentProps,
    DraftDataDataType,
    EditorMenuProps,
    MediaList, FileDataType
} from "./MediaUpload.interfaces";
import {useTranslation} from "react-i18next";
import useValidationNew from "../../../hooks/use-validation/use-validation-new";
import EditorMenu from "./EditorMenu";
import {t} from "i18next";
import {AxiosResponse} from "axios";
import {LinearProgressLabel} from "../index";


interface MediaUploadProps {
    children?: React.ReactNode;
    state: StateDataType,
    addMedia: (files: FileList | null, mainImage: boolean) => Promise<boolean>
    changeMainImage: (fileHash: string) => void
    changeMedia: (files: FileList | null, oldImageHash: string) => void
    onDeleteMedia: (mediaId: string) => void,
    maxLength: number,
    defaultMediaNumber: number,
    error?: string
}


let mainImage: boolean
let oldImageHash: string

const MediaUpload = (props: MediaUploadProps): JSX.Element => {
    const {
        maxLength,
        defaultMediaNumber,
        state,
        addMedia,
        changeMainImage,
        changeMedia,
        onDeleteMedia,
        error
    } = props

    const uploadFileRef = useRef<HTMLInputElement>(null)
    const updateFileRef = useRef<HTMLInputElement>(null)
    const [anchorEl, setAnchorEl] = React.useState <HTMLSpanElement | null>();
    const open = Boolean(anchorEl);
    const [width, setWidth] = useState<number>(window.innerWidth);
    const {t} = useTranslation();


    const isMobile = width <= 768;


    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }


    const [isBottom, setIsBottom] = React.useState(false);


    const onClickBoxHandler = (isMainImage: boolean, e: any) => {
        mainImage = isMainImage
        if (e.target.id == 'bigContainer' || e.target.id == 'midContainer') {
            if (Object.keys(state.medias).length < 1) {
                mainImage = true
            }
            uploadFileRef?.current?.click()
        } else if (e.target.id == 'icon' || e.target.id == 'basic-button') {
            setIsBottom(true);
        } else {
            setIsBottom(false);
        }
    }

    const onClickChangeMediaHandler = (hash: string, isMainImage: boolean) => {
        mainImage = isMainImage
        oldImageHash = hash
        updateFileRef?.current?.click()
    }


    const deleteImageHandler = (e: React.MouseEvent, mediaId: string) => {
        if (e.target == e.currentTarget) {
            e.stopPropagation()
        }

        onDeleteMedia(mediaId)
    }

    const onClickMenuItemHandler = (e: React.MouseEvent, handler: () => any) => {
        if (e.target == e.currentTarget) {
            e.stopPropagation();
        }
        handler();
    }

    const cameraSrc = (isMain: boolean) => {
        return isMain ? CameraWhite : Camera
    }

    useEffect(() => {
        // window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            // window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    const ImageBoxComponent = (imageBoxProps: ImageComponentProps) => {
        const {mediaKey, isMain = false} = imageBoxProps
        const src = mediaKey ? state.medias[mediaKey].url : cameraSrc(isMain)
        const medias = state.medias



        const boxClassName = () => {
            let className = `${styles.box}`

            if (isMain)
                className = `${className} ${styles.main}`

            if (!mediaKey){
                return `${className} cursor__pointer`
            }

            return `${className} ${styles.loaded}`
        }


        const containerProps: DynamicObject = {
            id: "bigContainer",
            className: boxClassName()
        }


        if (!mediaKey)
            containerProps.onClick = (e: any) => onClickMenuItemHandler(e, () => onClickBoxHandler(isMain, e))


        return <div {...containerProps}>
            <div className={styles.icon}>
                <img src={src} alt={'image'} id="midContainer"/>

            </div>
            {
                isMain && ((mediaKey && state.medias[mediaKey].isLoaded) || !mediaKey) &&
                <div className={styles.text}>{t('mainImage')}</div>
            }


            {
                (mediaKey && !medias[mediaKey].isLoaded) && (
                    <div className={styles["progress-container"]}>

                        <div className={styles['content']}>
                            <LinearProgressLabel variant="buffer" value={medias[mediaKey].progress}
                                                 container={{className: styles.progress}}/>
                        </div>
                    </div>
                )
            }


            {mediaKey &&
                <EditorMenu
                    mediaKey={mediaKey}
                    isMain={isMain}
                    onClickMenuItemHandler={onClickMenuItemHandler}
                    deleteImageHandler={deleteImageHandler}
                    onClickChangeMediaHandler={onClickChangeMediaHandler}
                    changeMainImage={changeMainImage}
                    state={state}
                />
            }

        </div>;
    }

    const mediaCount = Object.values(state.medias).length
    let numberMedia = mediaCount > (defaultMediaNumber - 3) ? (Math.floor(mediaCount / 3) * 3) + 3 : defaultMediaNumber
    if (numberMedia > maxLength)
        numberMedia = maxLength

    let mediaKeys = Object.keys(state.medias)

    const boxes = []

    const mainKey = state.main_media && state.medias[state.main_media] ? state.main_media : ''

    if (mainKey) {
        mediaKeys = mediaKeys.filter(item => item !== mainKey)
    }
    boxes.push(
        <div className="col-lg-4 col-6 mt-3">
            <ImageBoxComponent mediaKey={mainKey} isMain={true}/>
        </div>
    )

    mediaKeys = mediaKeys.sort(function (a,b){
        let n1, n2
        n1 = parseInt(a.split("-", 1)[0])
        n2 = parseInt(b.split("-", 1)[0])
        return n1 - n2;
    })

    console.log(mediaKeys[mediaKeys.length - 1], 'last len')

    for (let i = 2; i <= numberMedia; i++) {
        const mediaKey = mediaKeys.shift() as string
        boxes.push(
            <div className="col-lg-4 col-6 mt-3">
                <ImageBoxComponent mediaKey={mediaKey}/>
            </div>
        )
    }

    return (
        <>
            <input type='file' accept='image/*' className={styles["upload-file"]} ref={uploadFileRef} multiple={true}
                   onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                       addMedia(e.target.files, mainImage).then(() => {
                           e.target.value = ''
                       })
                   }}/>
            <input type='file' accept='image/*' className={styles["upload-file"]} ref={updateFileRef}
                   onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                       changeMedia(e.target.files, oldImageHash)
                   }}/>

            {error && <div className="alert alert-danger" role="alert">
                {error}
            </div>}
            <div className={`${styles.container} ${styles.w100}`}>
                <div className="row" style={{fontWeight: "bold"}}>
                    {boxes}
                </div>
            </div>
        </>
    )
}

export default MediaUpload