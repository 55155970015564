import {Props} from './interfaces';
import React, {useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import styles from './ListCellOptions.module.css'
import PostContext from "../../../../storage/PostContext";
import {Item} from "../../../../hooks/use-search/interfaces";
import DynamicObject from "../../../../models/dynamic-object";
import useSearch from "../../../../hooks/use-search/use-search";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {getImgPath, getSizes} from "../../../../helpers/functions";
import LanguageContext from "../../../../storage/LanguageContext";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";

const ListCellOptions = React.forwardRef<RefDataType<string>, Props>((props, ref) => {
    const {state, validation} = useDataPayload<string | number>({
        ref,
        config: props.config
    })


    const {config, nextApi} = props
    const {t} = useTranslation();
    const langCtx = useContext(LanguageContext)
    const postCtx = useContext(PostContext)


    const {filteredResults, Search} = useSearch({
        items: config.values,
        search: {
            placeholder: config?.search?.placeholder?.locale
        }
    })

    {/*STYLING HANDLER*/
    }
    const labelStyle = {
        fontWeight: config.cell.label.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.cell.label.style?.size),
        color: config.cell.label.color,
    } as React.CSSProperties


    {/*ACTION HANDLER*/
    }
    const addItemHandler = (item: Item) => {
        state.set(item.iMapperd)
        postCtx.addToForm(config.identifier, item.id)

        const httpConfig: DynamicObject = {
            config: {
                data: {}
            }
        }
        httpConfig["config"]["data"][config.identifier] = item.id
        if (item.target?.deeplink === 'xx/applyToJob/0') {
            httpConfig["config"]["flow_type"] = 'init-cv'
            postCtx.updateData({
                'flow_type': 'init-cv'
            })
        }
        nextApi?.request(httpConfig)
        if (item.target?.deeplink === 'xx/applyToJob/0') {
            postCtx.updateData({'flow_type': 'cv'})
        }
    }

    return (
        <div className={styles.container}>
            {/* SEARCH */}
            {config?.search &&
                <Search></Search>
            }
            {/* ITEMS */}
            <div className={styles.items_container}>
                {
                    filteredResults.length < 1 && (
                        <div className={`${styles.no_found_data}`}>
                            {t('noResults')}
                        </div>
                    )
                }
                {
                    filteredResults.map(item => {
                            if (item.id) {
                                return (
                                    <div key={item.id} className={`row ${styles.item}`}
                                         onClick={(e: React.MouseEvent<HTMLElement>) => addItemHandler(item)}>
                                        <div className={`col-md-9 col-10 ${styles.image_text}`}>
                                            {/*ITEM ICON*/}
                                            {

                                                item.icon && (
                                                    <div className={styles.image_container}>
                                                        <img className={styles.image} style={{width:config.identifier.includes("categoriesMain")?"50px":"30px"}} src={getImgPath(item.icon)}/>
                                                    </div>
                                                )
                                            }


                                            {/*ITEM LABEL*/}
                                            <div className={styles.text}>
                                                <h1 style={labelStyle}>{item.label}</h1>
                                            </div>
                                        </div>
                                        {/*ITEM ARROW */}
                                        <div className={`col-md-3 col-2 ${styles.icon}`}>
                                            {(langCtx.language == 'en') ?
                                                <IoIosArrowForward style={{height: "21.2px", width: "22.85px"}}/> :
                                                <IoIosArrowBack style={{
                                                    height: "21.2px",
                                                    width: "22.85px"
                                                }}/>}                                        </div>
                                    </div>
                                )
                            }
                            return (
                                <h3 style={{textAlign: "center"}}>{item.label}</h3>
                            )
                        }
                    )
                }
            </div>
            <ValidationError validation={validation}/>
        </div>
    )
})
export default ListCellOptions