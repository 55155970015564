import React from "react";
import styles from './MediaStep.module.css'
import {MediaStepProps} from "./interface";
import Media from "../../../UI/MediaUpload/Media";



const MediaSection = (props: MediaStepProps): JSX.Element => {

    const {config} = props

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>{config.title?.locale}</h1>
            </div>
            <div className={styles.content}>
                <Media identifier={config.fieldIdentifier}  />
            </div>

        </div>
    )
}

export default MediaSection