import {Modal} from "../../../UI";
import {Props} from "./interface";
import {useHttp} from "../../../../hooks";
import styles from './SelectField.module.css'
import {IoIosArrowDown} from "react-icons/io";
import {AxiosError, AxiosResponse} from "axios";
import PostContext from "../../../../storage/PostContext";
import {Args} from "../../../../hooks/use-http/interfaces";
import MapperSelectField from "../../../MapperSelectField";
import React, {useContext, useEffect, useState} from "react";
import DynamicObject from "../../../../models/dynamic-object";
import CircularProgress from '@mui/material/CircularProgress';
import {configWidgetApi} from "../../../../config/api.config";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import {getItemsAsString, getSizes, removeMatchingKeys} from "../../../../helpers/functions";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import {useTranslation} from "react-i18next";

const SelectField = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    const {config} = props

    /*--------------------------------------------------------------
     *                             HOOKS
     * ------------------------------------------------------------*/

    /************************************
     *   SET & GET VALIDATION AND STATES
     * *********************************/
    const {validation, state} = useDataPayload<string>({ref, config: props.config})

    /*---------------------------------------------------------------*/
    /************************************
     *           CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)


    /************************************
     *            STATES
     * *********************************/
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [btnLoader, setBtnLoader] = useState<boolean>(false)
    const [currentConfigApi, setCurrentConfigApi] = useState<DynamicObject>({})
    const [tempFormData, setTempFormData] = useState<DynamicObject>({})


    /************************************
     *           CONSTANTS
     * *********************************/
    const disabled = !config.editable ? styles.disabled : ''
    const widgetApi = useHttp<DynamicObject>(configWidgetApi)

    const selectedItems = postCtx.data.form[config.identifier]?.toString().split(',') ??[]
    const {t} = useTranslation()


    const request = widgetApi.request


    /************************************
     *        PREPARE REQUEST
     * *********************************/
    widgetApi.request = (args?: Args) => {
        const params: DynamicObject = {
            id: config.identifier,
            type: 'add-post',
            workflowId: postCtx.data.workflow_id,
            draftId: postCtx.data.draft_id,
            stepId: postCtx.data.step?.identifier
        }

        if (props.config?.parent?.length) {
            for (const field of props.config.parent) {
                params[field] = postCtx.data.form[field]
            }
        }

        return request({
            ...args,
            config: {
                ...args?.config,
                language: document.cookie.replace(/(?:(?:^|.*;\s*)lang\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
                params
            }
        })
    }

    /*--------------------------------------------------------------
     *                         FUNCTIONS
     * ------------------------------------------------------------*/

    /************************************
     *         ACTIONS HANDLER
     * *********************************/
    const onSuccessWidgetApi = (response: AxiosResponse) => {
        const data: DynamicObject = {}
        for (const key in props.flowRefs) {
            const ref = props.flowRefs[key]
            if (!ref?.current?.state?.get)
                continue

            const state = ref.current.state.get()
            const keys = key.match(/\b(\w+)\b/g) || []


            if (keys.length > 1) {
                let mainKey = keys[0] || ''
                if (!data[mainKey])
                    data[mainKey] = {}

                for (let i = 1; i < keys.length; i++) {
                    let name = keys[i]
                    data[mainKey][name] = state
                }
            } else {
                if (typeof state === "object") {
                    if (!data[key])
                        data[key] = {}

                    removeMatchingKeys(Object.keys(data[key]), state)
                    data[key] = {...data[key], ...state}
                } else {
                    data[key] = state
                }
            }
        }

        setTempFormData(data)
        setCurrentConfigApi(response.data)
        setIsOpen(true)
        setBtnLoader(false)

    }
    const onErrorWidgetApi = (response: AxiosError) => {
        setBtnLoader(false)
    }

    const onOpenHandler = () => {
        if (!config.editable)
            return

        setBtnLoader(true)
        widgetApi.request({
            callbacks: {
                success: onSuccessWidgetApi,
                error: onErrorWidgetApi
            }
        })
    }
    /*---------------------------------------------------------------*/


    /************************************
     *         INLINE STYLE
     * *********************************/
    const labelStyle = {
        color: config.title.color,
        fontSize: getSizes(config.title.style?.size),
        fontWeight: config.title.style?.bold ? "bold" : "normal",
    }
    const textStyle = {
        color: config.text.color,
        fontSize: getSizes(config.text?.style?.size),
        fontWeight: config.text?.style?.bold ? "bold" : "normal",
    }


    /************************************
     *           GET ITEMS
     * *********************************/
    var items = config.values.map(function (value, i) {


        if (selectedItems.includes(value.id + '')) {
            validation.clear()
            return value.label
        }


    })

    function initLocationItems() {
        items = selectedItems
        if (items[0] == "" || items[0] == "NaN") {
            items = []
            return
        }
        items = [`${t('selectedLocation')}`]



    }

    if (config.identifier.toLowerCase().includes("location")) {
        initLocationItems();
    }


    /************************************
     * PREVENT REDUNDANT IN RETURNED ITEMS
     * *********************************/
    items = items.filter((value, index, self) => value !== null && value !== undefined && self.indexOf(value) === index);


    return (

        <>
            <div id={config.identifier} className={`${disabled}  ${styles.container} `}>

                {isOpen && currentConfigApi &&
                    <Modal title={currentConfigApi.title} setIsOpen={setIsOpen}>
                        <MapperSelectField
                            tempFormData={tempFormData}
                            selectFieldConfig={config}
                            config={currentConfigApi} setConfigData={props.setConfigData}/>
                    </Modal>}

                {/* ITEMS */}
                <div className={`${styles.items_container} `}>
                    <div key={1} className={`row ${styles.item}`}
                         style={{pointerEvents: config.editable ? "auto" : "none"}}
                         onClick={(e: React.MouseEvent<HTMLElement>) => onOpenHandler()}>
                        <div className={`col-10 ${styles.image_text}`}>

                            {/*ITEM LABEL*/}
                            <div className={styles.text}>
                                <h1 style={labelStyle}>{config.title.locale}</h1>
                                {items.length > 0 &&
                                    <p className={`${styles.text_overflow}`}
                                       style={textStyle}>{getItemsAsString(items)}</p>
                                }
                            </div>
                        </div>
                        {/*ITEM ARROW */}
                        <div className={`col-2 ${styles.icon}`}>
                            <IoIosArrowDown style={{height: "21.2px", width: "22.85px"}}/>
                            {btnLoader && <CircularProgress/>}
                        </div>
                    </div>
                </div>

            </div>
            <ValidationError validation={validation}/>


        </>


    )
})

export default SelectField