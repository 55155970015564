import {Props} from './interfaces';
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {FaTimesCircle} from "react-icons/fa";
import styles from './ListCheckboxOptions.module.css'
import DynamicObject from "../../../../models/dynamic-object";
import useSearch from "../../../../hooks/use-search/use-search";
import {Checkbox, FormControlLabel, FormGroup, Typography} from "@mui/material";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import {getSizes} from "../../../../helpers/functions";
import UIContext from "../../../../storage/UIContext";

const ListCheckboxOptions = React.forwardRef<RefDataType<string>, Props>((props, ref) => {
    const {state, validation} = useDataPayload<string>({
        ref,
        config: props.config
    })
    const {config} = props
    const {t} = useTranslation();
    const checkedItems: DynamicObject = {}
    const uiCtx = useContext(UIContext)

    {/*SEARCH*/
    }
    const {filteredResults, Search} = useSearch({
        items: config.values,
        search: {
            placeholder: config?.search?.placeholder?.locale
        }
    })


    const addItemHandler = (itemValue: string) => {
        if (!state.value) {
            state.set(itemValue)
            return
        }
        state.set(state.value + (',' + itemValue))
    }

    const removeItemHandler = (itemValue: string) => {
        const oldItems = state.value ? (state.value).split(',') : []
        const newItems = oldItems.filter((item: string) => {
            return itemValue !== item
        })
        state.set(newItems.join(','))
    }

    const changeItem = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.checked) {
            removeItemHandler(e.target.value)
            return
        }
        addItemHandler(e.target.value)
        validation.clear()
    }

    state.value?.split(',')?.map((item: string) => {
        checkedItems[item] = true
    })


    return (
        <div className={`${styles.main_container}`}>
            {config?.search &&
                <Search></Search>
            }
            {props.config.tags && <div className={`row ${styles.checked_container}`}>
                {
                    config.values.map((item) => {
                        if (!checkedItems[item.id])
                            return

                        return (<div className={`${styles.checked_item_box}`}>
                            <div className={styles.checked_item_box_container}>
                                <span>{item.label}</span>
                                <FaTimesCircle className={`cursor__pointer`}
                                               onClick={(e: React.MouseEvent) => removeItemHandler(item.id)}/>
                            </div>
                        </div>)
                    })
                }
            </div>
            }

            <div className={`row mb-5  `}>
                <ValidationError validation={validation}/>

                <div className={`${styles.container} `} style={{overflowX: "hidden"}}>
                    <div className={`rounded fw-bold `}>
                        {
                            filteredResults.length < 1 && (
                                <div style={{
                                    padding: uiCtx.isMobile ? "40px" : "23vh 40px",
                                }} className={`row ${styles.no_found_data}`}>
                                    {t('noResults')}
                                </div>
                            )
                        }
                        {
                            filteredResults.map((item, i) => {

                                return <div className={`${styles.item}`}>
                                    <FormGroup>
                                        <FormControlLabel
                                            sx={{padding: "10px"}}
                                            control={
                                                <Checkbox
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeItem(e)}
                                                    checked={!!checkedItems[item.id]}

                                                />
                                            }
                                            label={
                                                <Typography sx={{
                                                    marginTop: "12px",
                                                    fontWeight: config.cell.label.style.bold ? 'bold' : 'normal',
                                                    fontSize: getSizes(config.cell.label.style.size),
                                                    color: config.cell.label.color
                                                }}>
                                                    {item.label}
                                                </Typography>
                                            }
                                            value={item.id}

                                        />
                                    </FormGroup>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
})

export default ListCheckboxOptions