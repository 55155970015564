import React, {useContext, useState} from "react";
import styles from './Button.module.css'
import {Props, RefDataType} from './interfaces';
import {Button as MuiButton} from "@mui/material";
import {getImgPath, getSizes} from "../../../../helpers/functions";
import Modal from "../../../UI/Modal/Modal";
import ConfirmationModel from "./ConfirmationModel";
import TakeAction from "../../../../api/take-action";
import PostContext from "../../../../storage/PostContext";
import {getDataStorage} from "../../../../helpers/storage.helper";

const configTakeAction = {
    callback: TakeAction,
    initialData: {},
    withLoader: true
}

const Button = React.forwardRef<RefDataType, Props>((props, ref) => {

    const postCtx = useContext(PostContext)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isYes, setIsYes] = useState(false)
    const storage = getDataStorage()


    // const takeActionApi = useHttp<DynamicObject>(configTakeAction)
    //
    //
    // const request = takeActionApi.request
    //
    // const onSuccessTakeActionApi = (response: AxiosResponse) => {
    //     postCtx.updateStep({
    //         workflow_id: response.data.workflow_id,
    //         draft_id: response.data.draft.id,
    //         step: response.data.step,
    //         start_time: Date.now(),
    //         previous: response.data.previous
    //     })
    // }
    //
    // takeActionApi.request = (args?: Args) => {
    //     return request({
    //         ...args,
    //         config: {
    //             ...args?.config,
    //             data: {
    //                 ...args?.config?.data,
    //                 ...postCtx.data.form,
    //                 draftId: postCtx.data,
    //                 time_spent_ms: Date.now() - postCtx.data.start_time,
    //
    //             }
    //         },
    //         callbacks: {
    //             ...args?.callbacks,
    //             success: onSuccessTakeActionApi,
    //         }
    //     })
    // }


    const buttonStyle = {
        fontWeight: props.config.text?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.text?.style?.size) ,
        color: props.config.text.color,
        textAlign: props.config.text.align,
        backgroundColor: props.config.background.color,
        borderColor: props.config.text.color,
    } as React.CSSProperties

    const onActionHandler = () => {
        if (props.config.confirmation) {
            setIsOpen(true)
        } else {
            if (props.config.target.webviewURL)
                window.open(props.config.target?.webviewURL);
            else {
                const deeplink = props.config.target.deeplink.replace("xx", storage.country)
                window.open(`https://opensooq.com/site/dl?requestUri=${deeplink}`)
            }
        }


    }

    const buttonActionHandler
        = (event: React.MouseEvent<HTMLElement>, value: boolean) => {
        if (value) {
            setIsYes(true)


        }
        setIsOpen(false);

    }
    return (
        <div>
            {
                isOpen && props.config?.confirmation &&
                <Modal title={props.config?.confirmation?.title} setIsOpen={setIsOpen}>
                    <ConfirmationModel config={props.config} buttonActionHandler={buttonActionHandler}/>
                </Modal>
            }
            <MuiButton variant='outlined' style={buttonStyle} fullWidth={true} endIcon={props.config.text?.icon &&
                <img style={{height: "25px", width: "25px"}} src={getImgPath(props.config.text?.icon)}></img>}
                       onClick={(e: React.MouseEvent<HTMLElement>) => onActionHandler()}
                       className={`d-block ${styles.button_body}`}>{props.config?.text.locale}</MuiButton>
        </div>


    )
})

export default Button