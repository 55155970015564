import React, {useContext} from "react";
import {Props} from "./interface";
import styles from './ListRadioOptions.module.css'
import UIContext from "../../../../storage/UIContext";
import PostContext from "../../../../storage/PostContext";
import DynamicObject from "../../../../models/dynamic-object";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";


const ListRadioOptions = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

        const {config} = props


        /************************************
         *             CONTEXT
         * *********************************/
        const postCtx = useContext(PostContext)
        const uiCtx = useContext(UIContext)

        /*-------------------------------------
         *              HOOKS
         * ----------------------------------*/

        /************************************
         *   SET & GET VALIDATION AND STATES
         * *********************************/
        const {state, validation} = useDataPayload<number | string>({
            ref,
            config: props.config
        })

        /*-------------------------------------
         *           FUNCTIONS
         * ----------------------------------*/

        /************************************
         *        ACTION HANDLER
         * *********************************/
        const addItemHandler = (id: number | string, label: string) => {
            state.set(id)
            // postCtx.addToForm(config.identifier, id)

            const formData = {...postCtx.data.form, ...props.tempFormData, [config.identifier]: id}

            postCtx.updateData({
                form: {
                    ...formData,

                }
            })
            uiCtx.setErrors([])


            const previewConfig: DynamicObject = postCtx.previewStep
            const lastPreview = previewConfig.childs.filter((child: any) => {
                if (child.identifier === config.identifier) {
                    const values = child.values
                    values[0] = {...values[0], label: label, id: id}
                    return {
                        ...child,
                        values: [...values]
                    }
                } else {
                    return child
                }
            })

            const finalConfig = {
                ...previewConfig,
                childs: lastPreview
            }
            props.setConfigData(finalConfig);
        }

        /*---------------------------------------------------------------*/


        /************************************
         *    GET ITEMS AS COMPONENT
         * *********************************/
        const items = config?.values?.map((item, i) => {
            return (
                <div className={`${styles.item} cursor__pointer`}
                     onClick={(e: React.MouseEvent<HTMLElement>) => addItemHandler(item.id, item.label)}>
                    <FormControlLabel control={<Radio/>}
                                      label={item.label} value={item.id}/>
                </div>)
        });

        return (
            <div

            >
                <div className={`${styles.body} rounded fw-bold`}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        defaultValue={state.value}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center"
                        }}
                    >
                        {
                            items
                        }
                    </RadioGroup>
                    <ValidationError validation={validation}/>
                </div>
            </div>
        )
    }
)
export default ListRadioOptions