import MyCv from "../api/my-cv";
import InitApi from "../api/init.api";
import NextApi from "../api/next.api";
import JobApplyApi from "../api/job-apply.api";
import DraftPostApi from "../api/draft-post.api";
import ActivatePost from "../api/activate-post";
import RepublishPost from "../api/republish-post";
import previousApi from "../api/previous.api";
import EditPostApi from "../api/edit-post.api";
import WidgetApi from "../api/widget.api";
import SaveDataApi from "../api/save-data.api";
import ScoreApi from "../api/score.api";
import TakeAction from "../api/take-action";

export const configTakeActionApi = {
    callback: TakeAction,
    initialData: {},
    withLoader: true
}

export const configGetScoreApi = {
    callback: ScoreApi,
    initialData: {},
    withLoader: true
}
export const configSaveApi = {
    callback: SaveDataApi,
    initialData: {},
    withLoader: true
}
export const configWidgetApi = {
    callback: WidgetApi,
    initialData: {},
    withLoader: false
}

export const configInitApi = {
    callback: InitApi,
    initialData: {},
    withLoader: true
}
export const configNextApi = {
    callback: NextApi,
    initialData: {},
    withLoader: true
}
export const configJobApplyApi = {
    callback: JobApplyApi,
    initialData: {},
    withLoader: true
}
export const configDraftApi = {
    callback: DraftPostApi,
    initialData: {},
    withLoader: true
}
export const configActivateApi = {
    callback: ActivatePost,
    initialData: {},
    withLoader: true
}
export const configRepublishApi = {
    callback: RepublishPost,
    initialData: {},
    withLoader: true
}
export const configMyCvApi = {
    callback: MyCv,
    initialData: {},
    withLoader: true
}
export const configPreviousApi = {
    callback: previousApi,
    initialData: {},
    withLoader: true
}
export const configEditPostApi = {
    callback: EditPostApi,
    initialData: {},
    withLoader: true
}