import {RefType, ValidationResult, Props, Result, StateResultRef, Validation} from "./interfaces";
import React, {ForwardedRef, useContext, useImperativeHandle, useRef, useState} from "react";
import PostContext from "../../storage/PostContext";
import ValidationError from "../../components/UI/ValidationError/ValidationError";
import validationError from "../../components/UI/ValidationError/ValidationError";


interface RefDataType<StateType> extends RefType<StateType> {
}


const useDataPayload = <StateType>(props: Props<StateType, RefDataType<any>>): Result<StateType> => {
    const {ref, config, stateConfig} = props
    useRef(null)

    const initializeState = <StateType>(identifier: string): StateType => {
        let value = postCtx.data.form


        const keys = identifier.match(/\b(\w+)\b/g) || []


        if (postCtx.data.flow_type == 'cv')
            if (value[identifier])
                return (value[identifier] as StateType)

        for (const key of keys) {
            value = value[key]
            if (!value)
                break
        }

        return (value as StateType)
    }

    const postCtx = useContext(PostContext)
    const [state, setState] = useState<StateType | undefined>(initializeState(config.identifier))
    const [error, setError] = useState<string>('')


    const validationResult: Validation = {
        set: (error: string) => {
            setError(error)
        },
        clear: () => {
            if (error)
                setError('')
        }
    }

    const oldRef = ref


    useImperativeHandle<RefDataType<StateType>, RefDataType<StateType>>(ref, () => {
        return {
            state: {
                set: setState,
                get: (() => state),
            },
            validation: validationResult

        }
    })

    const validation = {
        ...validationResult,
        error,
    }

    React.createElement(ValidationError, {
        validation,

    })

    return {
        state: {
            set: setState,
            value: state
        },
        validation: {
            ...validation
        }
    }
}

export {
    useDataPayload as default,
    type RefDataType
}



