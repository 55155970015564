import {useHttp} from "../../../hooks";
import MapperFlow from "../../MapperFlow";
import {ComponentLoader} from "../../UI";
import React, {useContext} from "react";
import UIContext from "../../../storage/UIContext";
import PostContext from "../../../storage/PostContext";
import {Args} from "../../../hooks/use-http/interfaces";
import DynamicObject from "../../../models/dynamic-object";
import JobApplySaveApi from "../../../api/job-apply-save.api";
import LanguageContext from "../../../storage/LanguageContext";


const JobApply = (): JSX.Element => {
    const postCtx = useContext(PostContext)

    const configNextApi = {
        callback: JobApplySaveApi,
        initialData: {},
        withLoader: true
    }

    const nextApi = useHttp<DynamicObject>(configNextApi)
    const request = nextApi.request
    const languageCtx = useContext(LanguageContext)
    const uiCtx = useContext(UIContext)


    function initializeData() {
        const data: DynamicObject = {
            draftId: postCtx.data.draft_id,
            time_spent_ms: Date.now() - postCtx.data.start_time,
            workflowId: postCtx.data.workflow_id,
            currentStep: postCtx?.data?.step?.identifier,
        }


        for (const key in postCtx.data.currentStepData) {
            data[key as keyof typeof data] = postCtx.data.form[key as keyof typeof postCtx.data.form]
        }
        return data;
    }

    nextApi.request = function (args?: Args) {
        const data = initializeData();

        request({
            ...args,
            config: {
                ...args?.config,
                data: {
                    ...args?.config?.data,
                    ...data
                },
                language: languageCtx.language,
                identifier: postCtx.data.step?.identifier,
                workflowId: postCtx.data.workflow_id,
            },
            callbacks: {
                ...args?.callbacks,
                before: () => {
                    uiCtx.setLoading(true)
                }
            }
        })
    }

    return (
        <div>
            {uiCtx.isLoading && <ComponentLoader/>}
            {
                postCtx.data?.step && !uiCtx.isLoading &&
                <MapperFlow config={postCtx.data.step}
                            nextApi={nextApi}/>
            }

        </div>
    )
}


export default JobApply