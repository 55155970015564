import React, {useEffect, useState} from "react";
import {Props} from "./interface";
import styles from "./TextArea.module.css"
import {getSizes} from "../../../../helpers/functions";
import {TextField as TextFieldMUI} from "@mui/material";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";


const TextArea = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    /*---------------------------------------------------------------
     *                            HOOKS
     * --------------------------------------------------------------*/

    /************************************
     *   SET & GET VALIDATION AND STATES
     * *********************************/
    const {validation, state} = useDataPayload<string>({ref, config: props.config})
    /*---------------------------------------------------------------*/

    /************************************
     *              STATES
     * *********************************/
    const [count, setCount] = useState(state.value?.length ?? 0);

    /************************************
     *        ACTION HANDLER
     * *********************************/
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        state.set(e.target.value)
    }

    /************************************
     *       CLEAR VALIDATION
     * *********************************/
    useEffect(() => {
        setCount(state.value?.length || 0)
        validation.clear()
    }, [state.value])


    /************************************
     *       SET ATTRIBUTES
     * *********************************/
    const attributes = {
        id: "outlined-multiline-static",
        fullWidth: true,
        multiline: true,
        rows: props.config.height == 1 ? 1 : props.config.height + 5,
        placeholder: props.config.placeholder.locale,
        InputProps: {
            style: {
                color: props.config.text.color,
                fontWeight: props.config.text?.style?.bold ? 'bold' : 'normal',
                fontSize: window.innerWidth<=768?'18px':getSizes(props.config.text?.style?.size),
                padding: "15px",
            },
            disableUnderline: true,
            readOnly:!props.config.editable,


        },


    }

    return (
        < >
            <div id={props.config.identifier} className={` ${styles.container}`}>
                <TextFieldMUI variant={"standard"} className={styles.text_area} {...attributes} onChange={onChange}
                              value={state.value} inputProps={{maxLength: props.config.limit}}

                />
                {props.config.counter&& <p style={{
                    marginTop: "-20px",
                    marginRight: "8px",
                    marginLeft: "8px",
                    opacity: "0.4",
                    fontSize: "10px",
                    display: "flex",
                    justifyContent: "flex-end"
                }}>{`${count} / ${props.config.limit}`}</p>}


            </div>
            <ValidationError validation={validation}/>
        </>
    )
})

export default TextArea