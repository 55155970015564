import {AxiosRequestConfig} from "axios";
import i18n from "../i18n";
import Cookies from 'js-cookie'
import {generateToken} from "../helpers/auth.helper";
import {getDataStorage} from "../helpers/storage.helper";

export default async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {

    const storage = getDataStorage()
    const device_uuid = storage.device_uuid || (storage.userInfo?.id || '')
    const lang = i18n.language.split("-", 2)[0]

    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    config.headers['source'] = storage.source
    config.headers['country'] = storage.country
    config.headers['release-version'] = '9.4.00'
    config.headers['session-id'] = device_uuid
    config.headers['X-Tracking-UUID'] = String(device_uuid);
    config.headers['accept-language'] = lang
    config.headers['enforce-language'] = lang
    config.headers['Authorization'] = "Bearer " + await generateToken()

    config.params = {
        ...config.params,
        expand: "remaining_edit_counter,media"
    }

    return config
}