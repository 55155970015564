import DynamicObject from "../models/dynamic-object";

const STEP_TYPES: string[] = [
    "normalStep",
    "mediaStep",
    "previewStep",
    "ifStep",
]

type TypeOfSize = {
    [key: string]: number;
};

const FONT_SIZES: TypeOfSize = {
    xs: 14,
    s: 16,
    m: 18,
    l: 22,
    xl: 24,
}
const MOBILE_FONT_SIZES: TypeOfSize = {
    xs: 8,
    s: 14,
    m: 17,
    l: 20,
    xl: 25,
}

export const mustache = (string: string, data: Object) => {
    return Object.entries(data).reduce((res, [key, value]) => {
        const search = `{${key}}`

        if (
            res.indexOf(search) !== -1 &&
            res.indexOf(`\\${key}`) == -1 &&
            res.indexOf(`${key}\\`) == -1
        ) {
            return res.replace(search, value)
        }

        return res
    }, string)
}


export const isStep = (type: string): boolean => {
    return STEP_TYPES.includes(type)
}

export const getSizes = (size?: string | undefined): string => {
    if (window.innerWidth <= 700) {
        size = MOBILE_FONT_SIZES[size || "m"] ? size : "m";
        return MOBILE_FONT_SIZES[size || "m"] + 'px';
    } else {
        size = FONT_SIZES[size || "m"] ? size : "m";
        return FONT_SIZES[size || "m"] + 'px';
    }
}

export const getImgPath = (img: string): string => {
    const replaceArray = ['platform', 'size'];
    const replaceWith = ['android', 'xxh'];

    for (let i = 0; i < replaceArray.length; i++) {
        img = img?.replace(new RegExp('{' + replaceArray[i] + '}', 'gi'), replaceWith[i]);
    }

    return img
}

export const getItemsAsString = (items: object): string => {

    return String(items).replace(/,*$/, '')
}
export const degreesToRadians = (degrees: number) => {
    return (degrees * Math.PI) / 180;
}

export const checkStringSquareBrackets = (value: string): { hasSquare: boolean, string: string, key?: string[] } => {
    const numBrackets = (value.match(/\[.*?\]/g) || []).length;
    const keys = value.split(/\[|\]/).filter(Boolean);
    const result = keys[0]
    delete keys[0]
    var hasSquare = false

    if (numBrackets > 0) {
        hasSquare = true
        const filteredArr = keys.filter(str => str !== '');
        return {
            hasSquare: hasSquare,
            key: filteredArr,
            string: result
        }
    }

    return {
        hasSquare: hasSquare,
        string: value
    }

}
export const getFinalPayload = (payload: DynamicObject): DynamicObject => {

    const filterKeys = ['workflowId', 'time_spent_ms', 'currentStep']

    for (var i = 0; i < filterKeys.length; i++) {
        let key = filterKeys[i]
        delete payload[key]
    }

    var finalPayload: DynamicObject = {}


    for (const value in payload) {
        let val = checkStringSquareBrackets(value)

        let mainKey = val.string
        if (!val.hasSquare) {
            finalPayload = {
                ...finalPayload,
                [`${mainKey}`]: payload[value]
            }

        } else {

            if (val.key?.length === 1) {
                finalPayload = {
                    ...finalPayload,
                    [`${mainKey}`]: {
                        ...finalPayload[mainKey],
                        [`${val.key}`]: payload[value]
                    }
                }
            } else {

                finalPayload = {
                    ...finalPayload,
                    [`${value}`]: payload[value]
                }
            }

        }
    }
    return finalPayload
}

export const getCurrentLocation = () => {
    return new Promise<GeolocationPosition>((resolve, reject) => navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => resolve(position),
        err => reject(err)
    ));
}


export const extractComponentValue = (form: DynamicObject, identifier: string) => {

}

export const getFieldType = (format: string): string => {
    let type = ""
    switch (format) {
        case "all":
        case "open":
            type = "text"
            break;
        default:
            type = "number"
    }
    return type
}


export function removeMatchingKeys(keys: string[], obj: object): void {
    for (const key of keys) {
        if (key in obj) {
            delete obj[key];
        }
    }
}

export function parentCallBack(msg): void {
    window?.parent?.postMessage?.(msg, '*')
    // window.parent.dispatchEvent(new Event(msg))
}

export function getUrlBasedOnCountry(country: string): string {
    const url = process.env.REACT_APP_JO_SOOQ_URL as string
    return url.replace("XX", country);
}

export const isIFrame = () => {
    try {
        return window.self !== window.top
    }catch (e) {
        return false
    }
}
export const toEnDigit = (s) => {
    return s.replace(/[\u0660-\u0669\u06f0-\u06f9]/g,    // Detect all Persian/Arabic Digit in range of their Unicode with a global RegEx character set
        function (a) {
            return a.charCodeAt(0) & 0xf
        }     // Remove the Unicode base(2) range that not match
    )
}
export const getInputType = (format:string) => {
    if (format === 'float'){
        return 'decimal';
    } else if (format === 'int'){
        return 'tel';
    } else {
        return 'text';
    }
}

export const jsonSafeGet = <T>(jsonString, defaultData = {}): T => {
    let output = defaultData as T;
    try {
        output = JSON.parse(jsonString);
    } catch (e) {
        // do nothing.
    }
    return output;
};