import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import styles from './CityFlowField.module.css'
import {Props, Starting} from './interfaces';
import DynamicObject from "../../../../models/dynamic-object";
import useSearch from "../../../../hooks/use-search/use-search";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import LanguageContext from "../../../../storage/LanguageContext";
import React, {useContext} from "react";
import {degreesToRadians, getCurrentLocation, getImgPath, getSizes} from "../../../../helpers/functions";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import PostContext from "../../../../storage/PostContext";

const CityFlowField = React.forwardRef<RefDataType<string>, Props>((props, ref) => {
    const {state, validation} = useDataPayload<number|string>({
        ref,
        config: props.config
    })

    const postCtx=useContext(PostContext)

    const {config, nextApi} = props
    const {t} = useTranslation();
    const langCtx = useContext(LanguageContext)

    {/* TITLE STYLE FOR CELL  */
    }
    const titleStyle = {
        fontWeight: config.cell.title.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.cell.title.style?.size),
        color: config.cell.title.color,
    } as React.CSSProperties

    {/* FILTERED RESULTS FROM THE SEARCH ACTION */
    }
    const {filteredResults, Search} = useSearch({
        items: config.values,
        search: {
            placeholder: config?.placeholder?.locale
        }
    })

    {/* ADD TO CONTEXT + REQUEST NEXT API */
    }

    const addItemHandler = (id: number | string) => {
        state.set(id)
        postCtx.addToForm(config.identifier, id)

        const httpConfig: DynamicObject = {
            config: {
                data: {}
            }
        }

        httpConfig["config"]["data"][config.identifier] = id

        nextApi?.request(httpConfig)
    }

    {/* CALCULATE DISTANCE BETWEEN CURRENT POSITIONS AND EXIST CITIES FROM THE API */
    }
    const calcCoordsDistance = (starting: Starting, destination: GeolocationPosition): number => {
        const startingLat = degreesToRadians(starting.lat);
        const startingLong = degreesToRadians(starting.lng);
        const destinationLat = degreesToRadians(destination.coords.latitude);
        const destinationLong = degreesToRadians(destination.coords.longitude);

        // Radius of the Earth in kilometers
        const radius: number = 6571;

        // Haversine equation
        const distanceInKilometers: number = Math.acos(Math.sin(startingLat) * Math.sin(destinationLat) +
            Math.cos(startingLat) * Math.cos(destinationLat) *
            Math.cos(startingLong - destinationLong)) * radius;

        return Math.floor(distanceInKilometers * 100) / 100;
    }

    {/* GET CURRENT LOCATION IF AROUND ME EXIST*/
    }
    const getClosestCountry = () => {
        getCurrentLocation().then((positions: GeolocationPosition) => {
            let closest: Starting = {
                lat: 0,
                lng: 0
            }
            let id = "";
            let near_distance = null;

            for (const key in config.values) {
                const value = config.values[key]
                const coordinates = {
                    lat: value.lat,
                    lng: value.lng
                }
                const closest_distance = calcCoordsDistance(coordinates, positions)
                if (near_distance === null || closest_distance < near_distance) {
                    near_distance = closest_distance;
                    closest = coordinates
                    id = value.id;
                }
            }

            if (id)
                addItemHandler(id)
        })

    }

    return (
        <div className={`${styles.container}`}>
            {/* SEARCH FIELD*/}
            <Search></Search>
            {/* AROUND ME , CURRENT LOCATION*/}
            {config?.aroundMe && <div className={`row ${styles.box} shadow`}>
                <div className={`col-12 ${styles.btn_header}`}>
                    <h1>
                        {t('aroundMe')}
                    </h1>
                </div>
                <div className='row'>
                    <div className='col-md-12 mb-2 '>
                        <Button onClick={getClosestCountry} variant="outlined" color="inherit" fullWidth={true}>
                            {t('useCurrentLocation')}
                        </Button>
                    </div>
                </div>
            </div>
            }
            {/* LIST CELL OPTIONS*/}
            <div className={styles.items_container}>
                {
                    filteredResults.length < 1 && (
                        <div className={`row ${styles.no_found_data}`}>
                            {t('noResults')}
                        </div>
                    )
                }
                {
                    filteredResults.map(item => {
                            return (
                                <div key={item.id} className={`row ${styles.item}`}
                                     onClick={(e: React.MouseEvent<HTMLElement>) => addItemHandler(item.id)}>
                                    <div className={`col-md-9 col-10 ${styles.image_text}`}>
                                        {/*ITEM ICON*/}
                                        {

                                            item.icon && (
                                                <div className={styles.image}>
                                                    <img src={getImgPath(item.cell_icon)}/>
                                                </div>
                                            )
                                        }


                                        {/*ITEM LABEL*/}
                                        <div className={styles.text}>
                                            <h1 style={titleStyle}>{item.label}</h1>
                                        </div>
                                    </div>
                                    {/*ITEM ARROW */}
                                    <div className={`col-md-3 col-2 ${styles.icon}`}>
                                        {(langCtx.language == 'en') ?
                                            <IoIosArrowForward style={{height: "21.2px", width: "22.85px"}}/> :
                                            <IoIosArrowBack style={{height: "21.2px", width: "22.85px"}}/>}
                                    </div>
                                </div>
                            )
                        }
                    )
                }
            </div>

        </div>)
})

export default CityFlowField