import {Props} from './interfaces';
import React, {useEffect} from "react";
import styles from './ListTagsOptions.module.css'
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import ValidationError from "../../../UI/ValidationError/ValidationError";

const ListTagsOptions = React.forwardRef<RefDataType<string>, Props>((props, ref) => {
    const {state, validation} = useDataPayload<string>({
        ref,
        config: props.config
    })



    const addItemHandler = (id: number) => {
        state.set(id.toString());

    }

    useEffect(function () {
        if (props.config?.values.length > 0 && !state.value) {
            addItemHandler(parseInt(props.config?.values[0]?.id) as number)
        }
    }, [props.config?.values])

    return (
        <div
            className={`${styles.container}`}>
            <div className={`${styles.tags_row}`}>
                {props.config?.values.map((item, index) => {
                    return (
                        <button key={index} name={item.label} onClick={(e) => addItemHandler(parseInt(item.id))}
                                className={parseInt(item.id) == parseInt(state.value as string) ? `${styles.customButton} ${styles.active}` : `${styles.customButton}`}>
                            {item.label}
                        </button>
                    )
                })}
            </div>
        </div>
    )
})
export default ListTagsOptions


