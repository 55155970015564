import Cookies from "js-cookie";
import {jsonSafeGet} from "./functions";

const urlParams = new URLSearchParams(window.location.search)
const cookiePrefix = urlParams.get('cookiePrefix') || ''

export type LiteUserInfo = {
    id: number;
    full_name: string;
    profile_picture: string;
    phone_number: string;
};

export type AuthTicketType = {
    t: number;
    k: string;
};

const getProductionStorage = () => {
    const source = Cookies.get('source') || 'desktop'
    console.log(jsonSafeGet<AuthTicketType>(Cookies.get('auth')),'kkkk')
    return {
        userInfo: jsonSafeGet<LiteUserInfo>(Cookies.get(cookiePrefix + 'userInfo')),
        tgtTimestamp: jsonSafeGet<AuthTicketType>(Cookies.get('auth')).t || Cookies.get(cookiePrefix + 'tgt_timestamp'),
        tgtTicket: jsonSafeGet<AuthTicketType>(Cookies.get('auth')).k || Cookies.get(cookiePrefix + 'tgt_ticket'),
        source: Cookies.get('source') || 'desktop',
        audience: urlParams.get('audience') || source,
        country: Cookies.get('ecountry') || 'jo',
        device_uuid: Cookies.get('device_uuid'),
    }
}

const getDevelopmentStorage = () => {
    console.log('dev')
    // return {
    //     userInfo: {id: 63826092},
    //     tgtTimestamp: '1675633807',
    //     tgtTicket: '80efe93bb492434af9e74efe7057a72a627bda66f493706bff7cb5c54a8872bf',
    //     source: 'desktop',
    //     country: 'jo'
    // }

    //shop member
    // return {
    //     userInfo: {id: 63826092},
    //     tgtTimestamp: '1675939015',
    //     tgtTicket: '5ac413b3ad51f9c5b33121d55411c003269d76f817cc491f88b018acb8797773',
    //     source: 'desktop',
    //     country: 'jo'
    // }
    //free member
    return {
        userInfo: {id: 65142082},
        tgtTimestamp: '1677417351',
        tgtTicket: '7e36abccd46b9f41a697fcf8f3610061899167b8dd02af5ef795cf12f8384a6e',
        source: 'ios',
        audience: 'ios',
        country: 'om',
        device_uuid: '',

    }
}

export const getDataStorage = () => {
    if (process.env.REACT_APP_ENV == "production")
        return getProductionStorage()

    return getDevelopmentStorage()
}