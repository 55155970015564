import React from "react";
import './VideoSection.css'
import {Props, RefDataType} from './interfaces';

const VideoSection = React.forwardRef<RefDataType, Props>((props, ref) => {


    return (
        <div className={`video-section`}>
        </div>
    )
})
export default VideoSection