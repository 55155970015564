import {Props} from "./interface";
import styles from './Button.module.css'
import Modal from "../../../UI/Modal/Modal";
import React, {useState} from "react";
import {Button as MuiButton} from "@mui/material";
import {RefDataTypePreview} from "../MainPropsPreview";
import {getImgPath, getSizes} from "../../../../helpers/functions";
import ConfirmationModal from "../../PreviewTools/Button/ConfirmationModal";
import {getDataStorage} from "../../../../helpers/storage.helper";


const Button = React.forwardRef<RefDataTypePreview, Props>((props, ref) => {


    /************************************
     *             STATES
     * *********************************/
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isYes, setIsYes] = useState(false)
    const storage = getDataStorage()


    /************************************
     *       INLINE BUTTON STYLE
     * *********************************/
    const buttonStyle = {
        fontWeight: props.config.text?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.text?.style?.size),
        color: props.config.text.color,
        textAlign: props.config.text.align,
        backgroundColor: props.config.background.color,
        borderColor: props.config.text.color,
    } as React.CSSProperties


    /*-------------------------------------
     *          FUNCTIONS
     * ----------------------------------*/

    /************************************
     *      BUTTON CLICK HANDLER
     * *********************************/
    const onClickHandler = () => {
        if (props.config.confirmation) {
            setIsOpen(true)
        } else {
            if (props.config.target.webviewURL)
                window.open(props.config.target?.webviewURL);
            else {
                const deeplink = props.config.target.deeplink.replace("xx", storage.country)
                window.open(`https://opensooq.com/site/dl?requestUri=${deeplink}`)
            }
        }
    }

    /************************************
     *     BUTTON ACTION HANDLER
     * *********************************/
    const buttonActionHandler = (event: React.MouseEvent<HTMLElement>, value: boolean) => {
        if (value) {
            setIsYes(true)
        }
        setIsOpen(false);
    }

    return (
        <div style={{marginTop: "20px"}}>
            {
                isOpen && props.config?.confirmation &&
                <Modal title={props.config?.confirmation?.title} setIsOpen={setIsOpen}>
                    <ConfirmationModal config={props.config} buttonActionHandler={buttonActionHandler}/>
                </Modal>
            }
            <MuiButton variant='outlined' style={buttonStyle} fullWidth={true} endIcon={props.config.text?.icon &&
                <img className={styles.img_icon} width="50px" height="50px"
                     src={getImgPath(props.config.text?.icon)}></img>}
                       onClick={(e: React.MouseEvent<HTMLElement>) => onClickHandler()}
                       className={`${styles.button_body}`}>{props.config?.text.locale}</MuiButton>
        </div>
    )
})

export default Button