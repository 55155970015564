import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {UiContextProvider} from "./storage/UIContext";
import {PostContextProvider} from "./storage/PostContext";
import {LanguageContextProvider} from "./storage/LanguageContext";
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
        <BrowserRouter>
            <UiContextProvider>
                <LanguageContextProvider>
                    <PostContextProvider>
                        <App/>
                    </PostContextProvider>
                </LanguageContextProvider>
            </UiContextProvider>
        </BrowserRouter>
);
reportWebVitals();


