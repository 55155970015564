import React, {useContext} from "react";
import {Props,RefDataType} from './interfaces';
import styles from './TextLabel.module.css'
import {getImgPath, getSizes} from "../../../../helpers/functions";
import LanguageContext from "../../../../storage/LanguageContext";
import UIContext from "../../../../storage/UIContext";

const TextLabel = React.forwardRef<RefDataType, Props>((props, ref) => {

    const uiCtx=useContext(UIContext)
    const langCtx=useContext(LanguageContext)
    const textStyle = {
        fontWeight: props.config.text?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.text?.style?.size) ,
        color: props.config.text.color,
        textAlign: props.config.text?.align,
        float: props.config.text?.align,
        marginTop:uiCtx.isMobile?"2px":""
    } as React.CSSProperties

    return (
        <div
            className={`${styles.container} mt-3 `}
        >
            <div className={`row`}>
                <div className={`col  d-flex justify-content-${props.config.text.align}`}>
                    <div style={{float:langCtx.language==='en' ?'left':'right'}}>{props.config.text.icon && <img style={{width:"20px"}} src={getImgPath(props.config.text.icon)} />}</div>
                    <div className={`ms-1`} style={textStyle}>{props.config.text.locale}</div>
                </div>
            </div>
        </div>
    )
})
export default TextLabel