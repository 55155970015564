// import styles from "./TextArea.module.css"
// import {Props, RefDataType} from './interfaces';
// import {getSizes} from "../../../../helpers/functions";
// import PostContext from "../../../../storage/PostContext";
// import React, {useContext, useImperativeHandle, useState} from "react";
// import {FormHelperText, TextField as TextFieldMUI} from "@mui/material";
// import LanguageContext from "../../../../storage/LanguageContext";
// import useValidationNew from "../../../../hooks/use-validation/use-validation-new";
//
// const TextArea = React.forwardRef<RefDataType, Props>((props, ref) => {
//
//     const postCtx = useContext(PostContext)
//     const [value, setValue] = useState<string>(postCtx.data.form[props.config.identifier])
//     const [count, setCount] = useState(value?.length ? value.length : 0);
//     const langCtx = useContext(LanguageContext)
//     const {error, setValidationError} = useValidationNew()
//     const alignCenter = (langCtx.language == 'en') ? 'left' : 'right'
//
//     const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//             setValue(e.target.value)
//             setCount(e.target.value?.length)
//         }
//
//     useImperativeHandle<RefDataType, RefDataType>(ref, () => {
//         return {
//             getState: (): string => value,
//             setValidationError
//         }
//     })
//
//         const attributes = {
//             id: "outlined-multiline-static",
//             fullWidth: true,
//             multiline: true,
//             rows: props.config.height,
//             placeholder: props.config.placeholder.locale,
//             InputProps: {
//                 style: {
//                     color: props.config.text.color,
//                     fontWeight: props.config.text?.style?.bold ? 'bold' : 'normal',
//                     fontSize: getSizes(props.config.text?.style?.size) ,
//                 }
//             },
//         }
//
//
//         return (
//             <div className={`mt-4 ${styles.container}`}>
//                 <TextFieldMUI className={styles.text_area}
//                               {...attributes} onChange={onChange}
//                               value={value} error={!!error}
//                               inputProps={{maxLength: props.config.limit}}/>
//                 <p style={{
//                     marginTop: "-20px",
//                     marginRight: "8px",
//                     marginLeft: "8px",
//                     opacity: "0.4",
//                     fontSize: "10px",
//                     display: "flex",
//                     justifyContent: "flex-end"
//                 }}>
//                     {`${count} / ${props.config.limit}`}
//                 </p>
//                 {error && <FormHelperText style={{
//                     color: "red",
//                     fontSize: "15px",
//                     paddingTop: "2px",
//                     textAlign: alignCenter
//                 }}>
//                     {`*  ${error}`}
//                 </FormHelperText>
//                 }
//             </div>
//         )
//     }
// )
//
// export default TextArea


import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import {getSizes} from "../../../../helpers/functions";
import {TextArea as TextAreaModel} from "../../../../models";
import styles from "../../Tools/TextArea/TextArea.module.css";
import {TextField as TextFieldMUI} from "@mui/material";
import React, {useEffect, useState} from "react";
import ValidationError from "../../../UI/ValidationError/ValidationError";

interface Props {
    config: TextAreaModel;
    className?: string,
    setConfigData?: React.Dispatch<React.SetStateAction<any>>;
    children?: React.ReactNode
}

const TextArea = React.forwardRef<RefDataType<string>, Props>((props, ref) => {
    const {validation, state} = useDataPayload<string>({ref, config: props.config})

    const [count, setCount] = useState(state.value?.length ?? 0);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        state.set(e.target.value)

    }

    useEffect(() => {
        setCount(state.value?.length || 0)
        validation.clear()
    }, [state.value])


    const attributes = {
        id: "outlined-multiline-static",
        fullWidth: true,
        multiline: true,
        rows: props.config.height == 1 ? 1 : props.config.height + 5,
        placeholder: props.config.placeholder.locale,
        InputProps: {
            style: {
                color: props.config.text.color,
                fontWeight: props.config.text?.style?.bold ? 'bold' : 'normal',
                fontSize: window.innerWidth<=768?'18px':getSizes(props.config.text?.style?.size),
            },
            readOnly: !props.config.editable,

        },


    }
    return (
        <div id={props.config.identifier} className={`pb-4`}>
            <TextFieldMUI
                className={styles.text_area} {...attributes} onChange={onChange}    error={!!validation.error}
                value={state.value}
                inputProps={{maxLength: props.config.limit}}/>

            {props.config.counter && <p style={{
                marginTop: "-20px",
                marginRight: "8px",
                marginLeft: "8px",
                opacity: "0.4",
                fontSize: "10px",
                display: "flex",
                justifyContent: "flex-end"
            }}>{`${count} / ${props.config.limit}`}</p>}

            <ValidationError validation={validation}/>
        </div>
    )
})

export default TextArea