import React, {useContext, useEffect, useImperativeHandle, useMemo, useState} from "react";
import {Select, MenuItem, SelectChangeEvent, FormHelperText} from "@mui/material";

import languageContext from "../../../../storage/LanguageContext";
import PostContext from "../../../../storage/PostContext";
import useValidationNew from "../../../../hooks/use-validation/use-validation-new";

import {DateField as DateFieldModel} from "../../../../models";
import RefType from "../../../../models/ref-type";
import {getSizes} from "../../../../helpers/functions";
import {formatDate, toDateObject, fromDateObject} from './functions';

import styles from './DateField.module.css';
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import ValidationError from "../../../UI/ValidationError/ValidationError";

export interface Props {
    config: DateFieldModel;
    className?: string
    setConfigData:React.Dispatch<React.SetStateAction<any>>;
}

const DateField = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    const {validation, state} = useDataPayload<string>({ref, config: props.config})
    const getDate = () => {
        const dateTimeDefault = new Date()
        dateTimeDefault.setDate((new Date()).getDate() + props.config.defaultValue)
        return formatDate(dateTimeDefault)
    }
    const [value,setValue] = useState<string>(state.value=="" || state.value==undefined?getDate:state.value)
    // const [value,setValue] = useState<string>(state.value==undefined || state.value==''?getDate:state.value)



    useEffect(() => {
        validation.clear()
    }, [state.value])


    const addDateHandler = (e: SelectChangeEvent<number>, type: string) => {
        let dateAsObject = toDateObject(value)
        const newDate = {
            ...dateAsObject,
            [type]: Number(e.target.value)
        }
        setValue(fromDateObject(newDate))
        state.set(fromDateObject(newDate))
    }


    const dateTimeMin = new Date()
    dateTimeMin.setDate(dateTimeMin.getDate() + props.config.minValue)
    const minYear = dateTimeMin.getFullYear()

    const dateTimeMax = new Date()
    dateTimeMax.setDate(dateTimeMax.getDate() + props.config.maxValue)
    const maxYear = dateTimeMax.getFullYear()

    const yearsComponents: Array<JSX.Element> = []
    const monthsComponents: Array<JSX.Element> = []
    const daysComponents: Array<JSX.Element> = []


    const getDays = (year:number, month:number) => {
        return new Date(year, month, 0).getDate();
    };

    {/* ACTION HANDLERS */
    }


    {/* MENU ITEMS */
    }
    for (let year = minYear; year <= maxYear; year++) {
        yearsComponents.push(<MenuItem value={year}>{year}</MenuItem>)
    }

    for (let month = 1; month <= 12; month++) {
        monthsComponents.push(<MenuItem disabled={month > new Date().getMonth() + 1 && toDateObject(value).year == new Date().getFullYear() } value={month}>{month}</MenuItem>)
    }

    for (let day = 1; day <= getDays(toDateObject(value).year, toDateObject(value).month); day++) {
        daysComponents.push(<MenuItem disabled={day > new Date().getDate()+1 && toDateObject(value).year == new Date().getFullYear() && toDateObject(value).month ==  new Date().getMonth()+1 } value={day}>{day}</MenuItem>)
    }


    {/*STYLING COMPONENT*/
    }
    const containerStyle = {
        color: props.config.text.color.normal ?? 'black',
        fontWeight: props.config.text?.style?.bold ? 'bold' : 'normal',
        fontSize:getSizes( props.config.text?.style?.size)
    }

    return (
        <div id={props.config.identifier} className={`row justify-content-center ${styles.row}`}>
            <div className="">{props.config.text?.locale}</div>
            <div className={`col-12 ${styles.col} date`} style={containerStyle}>
                <Select
                    MenuProps={{ PaperProps: { sx: { maxHeight: "20vh" } } }}
                    value={toDateObject(value).year}
                    onChange={(e: SelectChangeEvent<number>) => addDateHandler(e, 'year')}>
                    {yearsComponents}
                </Select>
                <Select
                    MenuProps={{ PaperProps: { sx: { maxHeight: "20vh" } } }}
                    value={toDateObject(value).month}
                    onChange={(e: SelectChangeEvent<number>) => addDateHandler(e, 'month')}>
                    {monthsComponents}
                </Select>
                <Select
                    MenuProps={{ PaperProps: { sx: { maxHeight: "20vh" } } }}
                    value={toDateObject(value).day}
                    onChange={(e: SelectChangeEvent<number>) => addDateHandler(e, 'day')}>
                    {daysComponents}
                </Select>
            </div>
            <ValidationError validation={validation}/>

        </div>
    )

})

export default DateField