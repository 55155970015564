import React from "react";
import './MediaSection.css'
import {Props,RefDataType} from './interfaces';

const MediaSection = React.forwardRef<RefDataType, Props>((props, ref) => {

    return (
        <div className={`media-section`} >
            Media Section Here
        </div>
    )
})
export default MediaSection