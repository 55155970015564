import ArachnaApi from './requests/arachna-api'
import {AxiosResponse} from "axios";
import DynamicObject from "../models/dynamic-object";
import {AddPostFilterResponse} from "../interceptors";

export default (config?: DynamicObject): Promise<AxiosResponse> => {
    const urlParams = new URLSearchParams(window.location.search)

    const publish = urlParams.get('flow') === "edit" ? "update" : "normal"
    const request = ArachnaApi.getInstance().request
    request.interceptors.response.use(AddPostFilterResponse().success,  AddPostFilterResponse().error)
    if (urlParams.get('flow') === "jobApply")
    {
        var url = `${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/cv/job-apply/finalize/${urlParams.get('post_id')}`

    }
    else
        var url = config?.component_name ? `${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/add-post/${publish}/finalize/${config?.workflowId}/${config?.component_name}` : `${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/add-post/${publish}/finalize/${config?.workflowId}`

    return request.post(`${url}`, {
        ...config?.data
    })
}