import React, {FC, Ref, RefObject, useEffect, useImperativeHandle} from "react";
import stepModule from '../Step.module.css'
import NextButton from "../../NextButton/NextButton";
import DynamicObject from "../../../../models/dynamic-object";
import {NormalStep as NormalStepModel} from '../../../../models'
import {UseHttpResponse} from "../../../../hooks/use-http/interfaces";
import RefType from "../../../../models/ref-type";
import {FaAngleLeft} from "react-icons/fa";
import Step from "../Step";
import {useTranslation} from "react-i18next";
import {getSizes} from "../../../../helpers/functions";

interface Props {
    children?: React.ReactNode;
    nextApi?: UseHttpResponse<DynamicObject>;
    config: NormalStepModel,
}

interface RefDataType extends RefType<DynamicObject> {
}


const NormalStep = React.forwardRef<RefDataType, Props>((props, ref): JSX.Element => {
    const {config, nextApi} = props
    const {t} = useTranslation ();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Step config={config} api={nextApi}>
            {/*{config.title && <div style={{padding: "0px 14px"}}>*/}
            {/*    <p style={{*/}
            {/*        fontWeight: config.title.style?.bold ? "bold" : "normal",*/}
            {/*        fontSize: getSizes(config.title.style?.size),*/}
            {/*        color: config.title.color,*/}
            {/*        marginBottom:"-25px"*/}
            {/*    }}>{config.title.locale}</p>*/}
            {/*</div>}*/}
            <div className={stepModule.content}>{props.children}</div>
            {
                config.submit && (
                    <NextButton
                        style={{
                            bold: config.submit.style?.bold,
                            size: config.submit.style?.size,
                            color: config.submit.color,
                            backgroundColor:config.submit.background.color
                        }}
                        isSticky={config.submit.sticky}
                        nextApi={nextApi}
                    >{config.submit.locale ? config.submit.locale :t('next')}</NextButton>
                )
            }
        </Step>
    )
})

export default NormalStep