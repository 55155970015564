import {QueryParamsResult} from "./interfaces";
import {useNavigate, useLocation} from 'react-router-dom'


const useQueryParams = (): QueryParamsResult => {
    const {search, pathname} = useLocation();
    const query = new URLSearchParams(document.location.search)
    const navigate = useNavigate()

    const addOrReplace = (name: string, value: string) => {
        query.set(name, value)
        navigate(`${pathname}?${query.toString()}`, {replace: true})
    }
    const set = (params: string) =>
    {
        const query = new URLSearchParams(params)
        navigate(`${pathname}?${query.toString()}`, {replace: true})
    }

    return {
        query,
        addOrReplaceQueryParam: addOrReplace,
        setQueryParams: set,
    };
}

export default useQueryParams