import React, {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next'

import {useQueryParams} from "../hooks";

const DEFAULT_LANGUAGE = "en"

const SUPPORTED_LANGUAGES = {
    en: {
        direction: "ltr"
    },
    ar: {
        direction: "rtl"

    }
}

const LanguageContext = React.createContext({

    language: DEFAULT_LANGUAGE,

    //actions
    setLanguage: (value: string) => {
    }
})


export const LanguageContextProvider = (props: { children: any }) => {

    //states
    const {query} = useQueryParams()
    const {t, i18n} = useTranslation()



    const setLanguageHandler = (value: string) => {
        let lang = value
        if (!SUPPORTED_LANGUAGES[value])
            lang = DEFAULT_LANGUAGE

        i18n.changeLanguage(lang).then(r => {
            const direction = SUPPORTED_LANGUAGES[lang].direction
            document.getElementsByTagName('html')[0].setAttribute('dir', direction);
            document.getElementsByTagName('html')[0].setAttribute('direction', direction);
            document.getElementsByTagName('html')[0].style.direction = direction;
        })
    }

    useEffect(() => {
        setLanguageHandler(query.get('lang'))
    }, [])



    return (
        <LanguageContext.Provider
            value={{
                language : i18n.language,
                setLanguage: setLanguageHandler
            }}>
            {props.children}
        </LanguageContext.Provider>
    );
}

export default LanguageContext