import React from "react";
import {Props} from "./interface";
import styles from './GridCheckboxOptions.module.css'
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";

const GridCheckboxOptions = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    /*-------------------------------------
    *              HOOKS
    * ----------------------------------*/

    /************************************
     *   SET & GET VALIDATION AND STATES
     * *********************************/
    const {state, validation} = useDataPayload<string>({
        ref,
        config: props.config
    })

    /************************************
     *         ACTION HANDLER
     * *********************************/
    const changeValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let valueArr = state.value ? state.value.split(',') : []
        valueArr = valueArr.filter((item) => {
            return item.length !== 0
        })
        if (!e.target.checked) {
            valueArr = valueArr.filter((item) => {
                return item !== e.target.value
            })
        } else {
            valueArr.push(e.target.value ?? '')
        }
        state.set(valueArr.join(','))
        validation.clear()
    }



    /************************************
     *            Items
     * *********************************/
    const items = props.config.values?.map((item) => {
        return <div className={`col-6 ${styles.col} justify-content-center`}>
            <div className={`${styles.checkbox_pill} m-1 p-2`}>
                <FormGroup>
                    <FormControlLabel control={<Checkbox inputProps={{'value': item.id}} size={"medium"}
                                                         onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeValueHandler(e)}/>}
                                      labelPlacement={"end"} label={item.title?.locale}/>
                </FormGroup>
            </div>
        </div>
    })

    return (
        <div className={`row justify-content-center p-1`}>
            {items}
            <ValidationError validation={validation}/>

        </div>
    )
})

export default GridCheckboxOptions