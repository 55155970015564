import {useHttp} from "../hooks";
import ScoreApi from "../api/score.api";
import * as flow from "./Flow/JobTools";
import {AxiosError, AxiosResponse} from "axios/index";
import PostContext from "../storage/PostContext";
import React, {ReactNode, useContext} from "react";
import DynamicObject from "../models/dynamic-object";
import NextButton from "./Flow/NextButton/NextButton";
import {Args, UseHttpResponse} from "../hooks/use-http/interfaces";
import UIContext from "../storage/UIContext";
import NextButtonModal from "./Flow/NextButton/NextButtonModal";

interface Props {
    config: DynamicObject;
    setConfigData: React.Dispatch<React.SetStateAction<any>>;
    saveHttp: UseHttpResponse<DynamicObject>
}

interface RefType {
    getState: () => DynamicObject
}
const flowRefs: DynamicObject = {}

const MapperTextLabelWithAction = (props: Props): JSX.Element => {
    /*CONSTANT*/
    const {config} = props
    const postCtx = useContext(PostContext)
    const uiCtx = useContext(UIContext)
    const data: DynamicObject = {}

    /*API || REQUEST CONFIG */
    const configGetScoreApi = {
        callback: ScoreApi,
        initialData: {},
        withLoader: true
    }
    const scoreHttp = useHttp<DynamicObject>(configGetScoreApi)

    /*PREPARE SCORE REQUEST*/
    const scoreRequest = scoreHttp.request
    scoreHttp.request = (args) => {

        const onSuccessScoreApi = (response: AxiosResponse) => {
            postCtx.addToForm('score', response.data.score)
        }

        scoreRequest({
            callbacks: {
                ...args?.callbacks,
                success: onSuccessScoreApi
            }
        })
    }

    /*PREPARE SAVE REQUEST*/
    const saveRequest = props.saveHttp.request

    props.saveHttp.request = (args?: Args) => {
        for (const key in flowRefs) {
            const ref = flowRefs[key]
            if (!ref?.current?.getState) {
                continue
            }

            const state = ref?.current?.getState()
            if (ref?.current?.getState){
                data[key] = state
            }
        }

        const oldSuccess = args?.callbacks?.success

        const success = (response: AxiosResponse) => {

            const previewConfig: DynamicObject = postCtx.previewStep
            const arrOfCompo = response.data.reRender
            const listOfIdentifier = arrOfCompo.map((obj: { identifier: any; }) => obj.identifier);
            const lastPreview = previewConfig.childs.map((child: any) => {
                if (listOfIdentifier.includes(child.identifier)) {
                    const compo = arrOfCompo.find((compo: { identifier: any; }) => compo.identifier === child.identifier);
                    return compo
                } else {
                    return child
                }
            })

            const finalConfig = {
                ...previewConfig,
                childs: lastPreview
            }

            postCtx.updateData({
                form: {
                    ...postCtx.data.form,
                    ...data
                },
                step: finalConfig

            })

            //get score from api
            const httpConfig: DynamicObject = {}
            scoreHttp?.request(httpConfig)

            if (oldSuccess)
                oldSuccess(response)
        }

        const error = (error: AxiosError) => {

            if (error.response?.status === 422) {
                const data = error.response?.data as DynamicObject
                for (const key in data.result.errors) {
                    const errorObject = data.result.errors[key]
                    const ref = flowRefs[errorObject.field]
                    if (ref.current) {
                        ref.current.setValidationError(errorObject.message)
                    }

                }
            }
            //
        }


        saveRequest({
            ...args,
            config: {
                ...args?.config,
                data: {
                    ...args?.config?.data,
                    ...data,
                }
            },
            callbacks: {
                ...args?.callbacks,
                success,
                error

            }
        })
    }


    /*MAPPER AND RECURSION TO GET ALL SUB COMPONENTS*/
    const Mapper = (config: DynamicObject) => {
        const typeName = config.type.capitalize();
        const identifier = config.identifier
        const attributes: DynamicObject = {}
        const Component = flow[typeName as keyof typeof flow]

        flowRefs[identifier] = React.createRef<RefType>()


        if (!Component) {
            throw new Error(`Component ${typeName} doesn't Exists`)
            return <></>
        }

        const items: ReactNode[] = []

        const components = config.childs && config.childs?.map((child: DynamicObject) => {
            items.push(Mapper(child))
        })



        // const x = config.submit ? <NextButtonModal
        //     style={{
        //         bold: config.submit?.style?.bold ?? 'bold',
        //         size: config.submit?.style?.size ?? 'medium',
        //         color: config.submit?.color ?? 'white',
        //     }}
        //     nextApi={props.saveHttp}
        // >{config.submit?.locale ? config.submit?.locale : "Next"}</NextButtonModal> : []

        // items.push(x)

        return (
            <div>


                    <Component saveHttp={props.saveHttp}
                               ref={flowRefs[config.identifier]}
                               setConfigData={props.setConfigData} config={config as any} {...attributes}>
                        {/*<div style={{*/}
                        {/*    overflow: "auto",*/}
                        {/*    height: "inherit"*/}
                        {/*}}>*/}
                        {items}
                    </Component>

            </div>
        )
    }

    return Mapper(config)
}

export default MapperTextLabelWithAction