import React, {useContext, useEffect, useState} from "react";
import {Switch} from "@mui/material";
import {AxiosResponse} from "axios";
import styles from "./Toggle.module.css"
import {useHttp} from "../../../../hooks";
import {Props, RefDataType} from "./interface";
import {getSizes} from "../../../../helpers/functions";
import PostContext from "../../../../storage/PostContext";
import DynamicObject from "../../../../models/dynamic-object";
import {configGetScoreApi, configSaveApi} from "../../../../config/api.config";

const Toggle = React.forwardRef<RefDataType, Props>((props, ref) => {

    /************************************
     *           CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)

    /************************************
     *           CONSTANTS
     * *********************************/
    const DEFAULT_VALUE = +(postCtx.data.form[props.config.identifier] || false)


    /************************************
     *           STATES
     * *********************************/
    const [value, setValue] = useState<number>(DEFAULT_VALUE)

    /************************************
     *             HOOKS
     * *********************************/

    const saveDataHttp = useHttp<DynamicObject>(configSaveApi)
    const scoreHttp = useHttp<DynamicObject>(configGetScoreApi)

    /************************************
     *    DATA & SCORE API REQUESTS
     * *********************************/
    const requestSave = saveDataHttp.request
    const requestSaveScore = scoreHttp.request

    scoreHttp.request = (args) => {

        const onSuccessScoreApi = (response: AxiosResponse) => {
            postCtx.addToForm('score', response.data.score)
        }

        requestSaveScore({
            callbacks: {
                ...args?.callbacks,
                success: onSuccessScoreApi
            }
        })
    }

    saveDataHttp.request = (args) => {
        const data = {
            ...args?.config?.data,
            [`${props.config.identifier}`]: value
        }

        const success = (response: AxiosResponse) => {

            const previewConfig: DynamicObject = postCtx.previewStep
            const arrOfCompo = response.data.reRender
            const listOfIdentifier = arrOfCompo.map((obj: { identifier: any; }) => obj.identifier);
            const lastPreview = previewConfig.childs.map((child: any) => {
                if (listOfIdentifier.includes(child.identifier)) {
                    const compo = arrOfCompo.find((compo: { identifier: any; }) => compo.identifier === child.identifier);
                    return compo
                } else {
                    return child
                }
            })

            const finalConfig = {
                ...previewConfig,
                childs: lastPreview
            }

            postCtx.updateData({
                form: {
                    ...postCtx.data.form,
                    ...data
                },
                step: finalConfig

            })

            //get score from api
            const httpConfig: DynamicObject = {}
            scoreHttp?.request(httpConfig)

        }
        requestSave({
            ...args,
            config: {
                ...args?.config,
                draftId: postCtx.data.draft_id,
                workflow_id: postCtx.data.workflow_id,
                time_spent_ms: Date.now() - postCtx.data.start_time,
                step: props.config.identifier,
                data: data
            },
            callbacks: {
                ...args?.callbacks,
                success: success
            }
        })
    }


    /************************************
     *       ACTIONS HANDLER
     * *********************************/
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked
        setValue(+e.target.checked)

    }

    useEffect(function () {
        if (value !== DEFAULT_VALUE && props.config.autoSave && postCtx.data.step?.identifier.includes('CV')) {
            saveDataHttp.request()
        }
    }, [value])


    /************************************
     *          CSS STYLE
     * *********************************/

    const containerStyle = {
        "--text-font-weight": props.config.text?.style?.bold ? "bold" : "normal",
        "--text-font-size": getSizes(props.config.text?.style?.size) + "px",
        "--text-font-color": props.config.text.color,
        "--text-font-align": props.config.text?.align,
    } as React.CSSProperties

    return (
        <div className={`${styles.container}`} style={containerStyle}>
            <div className={`row`}>
                <div className={`col-9 ${styles.center} `}>{props.config.text.locale}</div>
                <div className={`col-3 ${styles.center_toggle} `}>
                    <div className={`${styles.float_right}`}>
                        <Switch checked={!!value} size={"medium"} onChange={(e) => onChangeHandler(e)}></Switch>
                    </div>
                </div>
            </div>

        </div>)
})

export default Toggle
