import React, {useContext, useEffect, useState} from "react";
import {MediaStep as MediaStepModel} from "../../../../models";
import {UseHttpResponse} from "../../../../hooks/use-http/interfaces";
import DynamicObject from "../../../../models/dynamic-object";
import styles from './MediaStep.module.css'
import {FaRegCheckCircle} from "react-icons/fa";
import {MediaUpload} from "../../../UI";
import NextButton from "../../NextButton/NextButton";
import Step from "../Step";
import {useTranslation} from "react-i18next";
import PostContext from "../../../../storage/PostContext";
import {Box} from "@mui/material";
import Media from "../../../UI/MediaUpload/Media";
import UIContext from "../../../../storage/UIContext";

interface MediaStepProps {
    config: MediaStepModel;
    nextApi?: UseHttpResponse<DynamicObject>,
    className?: string
}

const MediaStep = (props: MediaStepProps): JSX.Element => {
    const {config, nextApi} = props
    const {t} = useTranslation();
    const postCtx = useContext(PostContext);
    const uiCtx=useContext(UIContext)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Step className={`${styles.container} mb-5` }>
            <div className={styles.header}>
                <div className={styles.headerTitle}>
                    {/*<h1>{config.title?.locale }</h1>*/}
                    <h1>{t('addMediaTitle')}</h1>
                </div>
                <div className={`${styles.flexContainer}`}>
                    <div><FaRegCheckCircle className={`${styles.verticalAlign}   ${styles.icon} ${styles.customIcon}`}/>
                    </div>
                    <div className={`ms-1`}><span
                        className={styles.text}>{t('youCanAddUp')} {config?.config?.numberOfImages || 30} {t('image')}</span>
                    </div>
                </div>
                <div className={`${styles.flexContainer}`}>
                    <div><FaRegCheckCircle className={`${styles.verticalAlign} ${styles.icon} ${styles.customIcon}`}/>
                    </div>
                    <div className={`ms-1`}><span className={styles.text}>{t('increaseImageCountNotice')}</span></div>
                </div>
            </div>
            <div className={styles.content}>
                <Media identifier={config.fieldIdentifier}  />
            </div>
            <NextButton
                style={{
                    bold: true,
                    size: "m",
                    color: "#fff",
                    backgroundColor:"#0C5CF9"
                }}
                isSticky={true}
                nextApi={nextApi}
            >{t('next')}</NextButton>
        </Step>
    )
}

export default MediaStep