import React from "react";
import {Props} from "./interface";
import {HiPlusCircle} from "react-icons/hi";
import styles from './CardDetails.module.css'
import {getSizes} from "../../../../helpers/functions";


const CardDetails = (props: Props) => {


    /************************************
     *       INLINE TITLE STYLE
     * *********************************/
    const titleStyle = {
        fontWeight: props.config.title.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.title.style?.size) + "px",
        color: props.config.title.color,
    } as React.CSSProperties

    /************************************
     *       INLINE SUBTITLE STYLE
     * *********************************/
    const subTitleStyle = {
        fontWeight: props.config.subTitle.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.subTitle.style?.size) + "px",
        color: props.config.subTitle.color,
    } as React.CSSProperties

    /************************************
     *       INLINE ITEM TITLE STYLE
     * *********************************/
    const itemTitleStyle = {
        fontWeight: props.config.items.title.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.items.title.style?.size) + "px",
        color: props.config.items.title.color,
    } as React.CSSProperties

    /************************************
     *       INLINE BACKGROUND STYLE
     * *********************************/
    const backgroundStyle = {
        backgroundColor: props.config.background,
    } as React.CSSProperties


    return (<div
        className={`${styles.container}container`}
    >
        <div style={backgroundStyle} className={`${styles.box}container`}>
            <div className="row">
                <h1 style={titleStyle}>{props.config.title.locale}</h1>
                <p style={subTitleStyle}>{props.config.subTitle.locale}
                </p>
            </div>
            <div className="row mt-4">
                <div className={`${styles.icon_text_container}`}><HiPlusCircle
                    className={`${styles.icon}`}>
                </HiPlusCircle>
                    <span style={itemTitleStyle}>{props.config.items.title.locale}</span></div>
            </div>
        </div>
    </div>)
}
export default CardDetails














